import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { INotificationsRequest, ISubscribeRequest } from 'redux/reducers/notifications/types';
import { INotificationsApi } from './types';

export const notifications: INotificationsApi = {
	getNotifications: (params: INotificationsRequest) =>
		http.get(endpoint.notifications.NOTIFICATION, { params }),
	getNotificationsBell: () => http.get(endpoint.notifications.NOTIFICATION_BELL),
	postNotificationsAllRead: () => http.post(endpoint.notifications.NOTIFICATION),
	subscribe: (params: ISubscribeRequest) => http.post(endpoint.notifications.SUBSCRIBE(params)),
	unsubscribe: () => http.delete(endpoint.notifications.UNSUBSCRIBE),
};
