/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ISagaDepFitReq } from 'components/WalletsComponents/type';
import {
	IApartmentInvestResponse,
	ICalculateRequest,
	ICalculateResponse,
	ICommisionCalculate,
	ICommisionCalculateResponse,
	ICommisionResponse,
	IConvertRequest,
	IConvertResponse,
	ICreateUsdcRequest,
	ICreateUsdcResponse,
	IDividendsAllResponse,
	IDividendsResponse,
	IHistoryConvertsResponse,
	IInvestments,
	IPaginationRequest,
	ITokensCurrentResponse,
	IWalletsHistoryRequest,
	IWalletsHistoryResponse,
	IWalletsResponse,
	IWalletState,
	IWithdrawUsdcRequest,
	TLimitResponse,
} from './types';

export const initialState: IWalletState = {
	wallets: null,
	walletsLoader: false,
	walletsHistory: null,
	walletsHistoryLoader: false,
	walletUsdCreateLoader: false,
	walletUsdcCreateLoader: false,
	usdcQr: null,
	convertLoader: false,
	convert: null,
	calculate: null,
	calculateLoader: false,
	commision: null,
	commisionCalculate: null,
	limit: null,
	dividendsAll: null,
	dividendsHistory: null,
	dividendsHistoryLoader: false,
	currentBalanceTokens: null,
	sumTokens: 0,
	investmentsAll: null,
	historyConverts: null,
};

const walletSlice = createSlice({
	name: '@@wallet',
	initialState,
	reducers: {
		walletBalanceRequest: (state) => {
			const walletBalanceState = state;
			walletBalanceState.walletsLoader = true;
		},

		walletBalanceSuccess: (state, action: PayloadAction<IWalletsResponse>) => {
			const walletBalanceState = state;
			const { payload } = action;

			walletBalanceState.walletsLoader = false;
			walletBalanceState.wallets = payload;
		},

		walletsHistoryRequest: (state, action: PayloadAction<IWalletsHistoryRequest>) => {
			const walletsHistoryState = state;
			walletsHistoryState.walletsLoader = true;
		},

		walletsHistorySuccess: (state, action: PayloadAction<IWalletsHistoryResponse | null>) => {
			const walletsHistoryState = state;
			const { payload } = action;

			walletsHistoryState.walletsLoader = false;
			walletsHistoryState.walletsHistory = payload;
		},

		getInvestmentsAllReguest: (state, action: PayloadAction<IWalletsHistoryRequest>) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.walletsLoader = true;
		},

		getInvestmentsAllSuccess: (state, action: PayloadAction<IApartmentInvestResponse>) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.walletsLoader = false;

			transactionsHistoryState.investmentsAll = payload;
		},

		createUsdWalletRequest: (state, action: PayloadAction<ISagaDepFitReq>) => {
			const createUsdWalletState = state;
			createUsdWalletState.walletUsdCreateLoader = true;
		},

		createUsdWalletSuccess: (state) => {
			const createUsdWalletState = state;
			createUsdWalletState.walletUsdCreateLoader = false;
		},

		createUsdcWalletRequest: (state, action: PayloadAction<ICreateUsdcRequest>) => {
			const createUsdcWalletState = state;
			createUsdcWalletState.walletUsdcCreateLoader = true;
		},

		createUsdcWalletSuccess: (state, action: PayloadAction<ICreateUsdcResponse | null>) => {
			const { payload } = action;

			const createUsdcWalletState = state;
			createUsdcWalletState.walletUsdcCreateLoader = false;
			createUsdcWalletState.usdcQr = payload;
		},

		withdrawUsdcWalletRequest: (state, action: PayloadAction<IWithdrawUsdcRequest>) => {
			const withdrawUsdcWalletState = state;
			withdrawUsdcWalletState.walletUsdcCreateLoader = true;
		},

		withdrawUsdcWalletSuccess: (state) => {
			const withdrawUsdcWalletState = state;
			withdrawUsdcWalletState.walletUsdcCreateLoader = false;
		},

		convertRequest: (state, action: PayloadAction<IConvertRequest>) => {
			const convertState = state;
			convertState.convertLoader = true;
		},

		convertSuccess: (state, action: PayloadAction<IConvertResponse>) => {
			const { payload } = action;

			const convertState = state;
			convertState.convertLoader = false;
			convertState.convert = payload;
		},

		calculateRequest: (state, action: PayloadAction<ICalculateRequest>) => {
			const calculateState = state;
			calculateState.calculateLoader = true;
		},

		calculateSuccess: (state, action: PayloadAction<ICalculateResponse | null>) => {
			const { payload } = action;

			const calculateState = state;
			calculateState.calculateLoader = false;
			calculateState.calculate = payload;
		},

		commisionCalculateRequest: (state, action: PayloadAction<ICommisionCalculate>) => {
			const calculateState = state;
			calculateState.calculateLoader = true;
		},

		commisionCalculateSuccess: (
			state,
			action: PayloadAction<ICommisionCalculateResponse | null>,
		) => {
			const { payload } = action;

			const calculateState = state;
			calculateState.calculateLoader = false;
			calculateState.commisionCalculate = payload;
		},

		limitRequest: (state) => {
			const calculateState = state;
			calculateState.calculateLoader = true;
		},

		limitSuccess: (state, action: PayloadAction<TLimitResponse | null>) => {
			const { payload } = action;

			const calculateState = state;
			calculateState.calculateLoader = false;
			calculateState.limit = payload;
		},

		commisionRequest: () => {},

		commisionSuccess: (state, action: PayloadAction<ICommisionResponse>) => {
			const { payload } = action;

			const calculateState = state;
			calculateState.commision = payload;
		},

		dividendsAllRequest: () => {},

		dividendsAllSuccess: (state, action: PayloadAction<IDividendsAllResponse>) => {
			const { payload } = action;

			const dividendsAll = state;
			dividendsAll.dividendsAll = payload;
		},

		dividendsHistoryRequest: (state, action: PayloadAction<IPaginationRequest>) => {
			const dividendsHistory = state;
			dividendsHistory.dividendsHistoryLoader = true;
		},

		dividendsHistorySuccess: (state, action: PayloadAction<IDividendsResponse>) => {
			const { payload } = action;
			const dividendsHistory = state;
			dividendsHistory.dividendsHistoryLoader = false;
			dividendsHistory.dividendsHistory = payload;
		},

		getCurrentBalanceTokensRequest: (state, action: PayloadAction<IPaginationRequest>) => {
			const dividendsHistory = state;
			dividendsHistory.dividendsHistoryLoader = true;
		},

		getCurrentBalanceTokensSuccess: (state, action: PayloadAction<ITokensCurrentResponse>) => {
			const { payload } = action;
			const dividendsHistory = state;
			dividendsHistory.dividendsHistoryLoader = false;
			dividendsHistory.currentBalanceTokens = payload.investments;
			dividendsHistory.sumTokens = payload.sum;
		},

		getHistoryConvertsReguest: (state, action: PayloadAction<IWalletsHistoryRequest>) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.walletsLoader = true;
		},

		getHistoryConvertsSuccess: (state, action: PayloadAction<IHistoryConvertsResponse>) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.walletsLoader = false;

			transactionsHistoryState.historyConverts = payload;
		},

		walletInitState: () => initialState,
	},
});

export default walletSlice.reducer;
export const {
	walletBalanceRequest,
	walletBalanceSuccess,
	walletsHistoryRequest,
	walletsHistorySuccess,
	walletInitState,
	createUsdWalletRequest,
	createUsdWalletSuccess,
	createUsdcWalletRequest,
	createUsdcWalletSuccess,
	withdrawUsdcWalletRequest,
	withdrawUsdcWalletSuccess,
	convertRequest,
	convertSuccess,
	calculateRequest,
	calculateSuccess,
	commisionRequest,
	commisionSuccess,
	commisionCalculateRequest,
	commisionCalculateSuccess,
	limitRequest,
	limitSuccess,
	dividendsAllRequest,
	dividendsAllSuccess,
	dividendsHistoryRequest,
	dividendsHistorySuccess,
	getCurrentBalanceTokensRequest,
	getCurrentBalanceTokensSuccess,
	getInvestmentsAllReguest,
	getInvestmentsAllSuccess,
	getHistoryConvertsReguest,
	getHistoryConvertsSuccess,
} = walletSlice.actions;
