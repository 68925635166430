import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	cancelActiveOrderRequest,
	cancelActiveOrderSuccess,
	deleteWishlistRequest,
	deleteWishlistSuccess,
	getActiveOrdersRequest,
	getActiveOrdersSuccess,
	getInvestmentsRequest,
	getInvestmentsSuccess,
	getWishlistRequest,
	getWishlistSuccess,
	orderModifyRequest,
	orderModifySuccess,
	portfolioInitState,
} from './reducer';
import {
	IActiveOrdersResponse,
	IInvestmentsResponse,
	IOrderModifyRequest,
	IOrderModifyResponse,
	IWishlistRequest,
	IWishlistResponse,
} from './types';
// import { notificationContainer } from 'services/utils/notificationContainer';
// import L from 'i18n-react';

function* getWishlistWorker(action: PayloadAction<IWishlistRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IWishlistResponse } = yield call(api.portfolio.getWishlist, payload);
		yield put(getWishlistSuccess(response.data.items));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

function* deleteWishlistWorker(action: PayloadAction<number>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.portfolio.deleteWishlist, payload);
		yield put(deleteWishlistSuccess(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

function* getInvestmentstWorker(action: PayloadAction<IWishlistRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IInvestmentsResponse } = yield call(
			api.portfolio.getInvestments,
			payload,
		);
		yield put(getInvestmentsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

function* getActiveOrdersWorker(action: PayloadAction<IWishlistRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IActiveOrdersResponse } = yield call(
			api.portfolio.getActiveOrders,
			payload,
		);
		yield put(getActiveOrdersSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

function* cancelActiveOrderWorker(action: PayloadAction<number>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.portfolio.cancelActiveOrder, payload);
		yield put(cancelActiveOrderSuccess(payload));
		yield notificationContainer('The order was successfully canceled', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

function* orderModifyWorker(action: PayloadAction<IOrderModifyRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IOrderModifyResponse } = yield call(api.portfolio.orderModify, payload);
		yield put(orderModifySuccess(response.data));
		yield payload.success(true);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(portfolioInitState());
	}
}

export function* portfolioSaga() {
	yield takeEvery(getWishlistRequest.type, getWishlistWorker);
	yield takeEvery(deleteWishlistRequest.type, deleteWishlistWorker);
	yield takeEvery(getInvestmentsRequest.type, getInvestmentstWorker);
	yield takeEvery(getActiveOrdersRequest.type, getActiveOrdersWorker);
	yield takeEvery(cancelActiveOrderRequest.type, cancelActiveOrderWorker);
	yield takeEvery(orderModifyRequest.type, orderModifyWorker);
}
