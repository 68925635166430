import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	IAddPictures,
	IValidationSchemaSellYuorResponse,
} from 'components/Forms/FormSellYourProperty/types';
import {
	IDocumentComplitedRequest,
	IPropertyDetailsRequest,
	IPropertyRequest,
	ISingDocumentsReques,
	IWaitListPostRequest,
	IWaitListRequest,
} from 'redux/reducers/market/types';
import { IAddNewOrderrequest } from 'redux/reducers/orders/types';
import { IMarketApi } from './types';

export const market: IMarketApi = {
	sellProperty: (payload: IValidationSchemaSellYuorResponse) =>
		http.post(endpoint.market.SELL_YUOR_PROPERTY, payload),
	addPictures: (payload: IAddPictures) => http.post(endpoint.market.ADD_PICTURES, payload),
	getProperty: (params: IPropertyRequest) => http.get(endpoint.market.GET_ALL_PROPERTY, { params }),
	getPropertyDetails: (params: IPropertyDetailsRequest) =>
		http.get(endpoint.market.PROPERTY_DETAILS(String(params.id))),
	postWaitList: (payload: IWaitListPostRequest) => http.post(endpoint.market.WAITLIST_ADD, payload),
	wishlistAdd: (payload: IWaitListRequest) => http.post(endpoint.market.WISHLIST_ADD, payload),
	getTokenPrice: () => http.get(endpoint.market.GET_TOKEN_PRICE),
	getCalculateAmount: (params: number) => http.get(endpoint.market.GET_CALCULATE_AMOUNT(params)),
	postOrdersSell: (params: number) => http.post(endpoint.orders.ORDERS_SELL(params)),
	postOrdersBuy: (params: number) => http.post(endpoint.orders.ORDERS_BUY(params)),
	createOrders: (payload: IAddNewOrderrequest) =>
		http.put(endpoint.orders.ORDERS_CREATE, payload.data),
	proposeOrders: (payload: IAddNewOrderrequest) =>
		http.post(endpoint.orders.ORDERS_TRADE, payload.data),
	getIdWishList: () => http.get(endpoint.market.GET_WISHLIST_ID),
	getSingDocuments: (params: ISingDocumentsReques) =>
		http.put(endpoint.market.GET_SING_DOCUMENTS(params.params)),
	createInvestOrSing: () => http.get(endpoint.market.CREATE_INVEST),
	addComplited: (payload: IDocumentComplitedRequest) =>
		http.post(endpoint.market.ADD_COMPLETED, payload),
	removeWishlistId: (params: number) =>
		http.delete(endpoint.orders.ORDERS_DELETE_FROM_WISHLIST(params)),
	getStates: () => http.get(endpoint.market.STATES_LIST),
};
