import { authInitState } from 'redux/reducers/auth/reducer';
import { errors } from 'redux/reducers/errors/reducer';
import { store } from 'redux/store';
import { notificationContainer } from 'services/utils/notificationContainer';
import L from 'i18n-react';
import { IAxiosError, IAxiosRequestConfig } from './types';

// ==========================================:
const onError = (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorName = error.response ? error.response.data : null;
	const errorResponse: IAxiosRequestConfig = error.config;
	store.dispatch(errors(status));

	if (status === 401) {
		store.dispatch(authInitState());

		if (errorName.error === 'Unauthorized') {
			notificationContainer(`${String(L.translate('invalid_credentials'))}`, 'info');
		} else {
			notificationContainer(`${String(L.translate('session_has_ended'))}`, 'info');
		}
	}
	if (status === 403 && errorName.error === 'user_unverified') {
		notificationContainer(`user_unverified`, 'error');
	}

	if (status === 403 && !errorResponse?._retry && errorName?.errors?.includes('user_blocked')) {
		notificationContainer(`user_blocked`, 'error');
	}

	if (status === 403 && errorName?.errors.includes('email_not_confirmed')) {
		notificationContainer('Email not confirmed', 'error');
	}

	return Promise.reject(error);
};

export default onError;
