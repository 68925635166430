/* eslint-disable no-console */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import L from 'i18n-react';
import { IGenerate2faKeyResponse } from 'services/api/settings/types';
import { E2FaMassage } from 'services/constants/twoFaMassage';
import { ELinksName } from 'services/constants/listLinks';
import { authInitState, userSuccess } from '../auth/reducer';
import { IUserResponse } from '../auth/types';
import {
	changeUserPassRequest,
	checkPassRequest,
	checkPassSuccess,
	disable2faRequest,
	disable2faSmsPhoneRequest,
	disable2faSmsPhoneSuccess,
	disable2faSuccess,
	editUserRequest,
	editUserSuccess,
	enable2faRequest,
	enable2faSmsPhoneRequest,
	enable2faSmsPhoneSuccess,
	enable2faSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	getPdfRequest,
	getSmsCode2faLoginRequest,
	getSmsCode2faLoginSuccess,
	getSmsCodeRequest,
	open2FaPageSuccess,
	openPopUpGoogle2FaSuccess,
	openPopUpSms2FaSuccess,
	postPdfRequest,
	postPdfResponse,
	settingsInitState,
} from './reducer';
import {
	I2faSmsPayload,
	ICheckPassRequest,
	ICheckPassSagaResponse,
	ICreatePasswordFormData,
	// I2faSmsPayload,
	IDisable2faPayload,
	IEditUserRequest,
	IEnable2faPayload,
	IGetSmsRequest,
	ITotpResponse,
} from './types';

function* editUserWorker(action: PayloadAction<IEditUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IUserResponse = yield call(api.settings.editUser, payload);
		yield put(editUserSuccess());
		yield put(userSuccess(response.data));
		notificationContainer(String(L.translate(`successful`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* generate2faKeyWorker() {
	try {
		yield put(showLoading());
		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
		yield put(generate2faKeySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITotpResponse = yield call(api.settings.enable2fa, payload);
		yield put(enable2faSuccess());
		yield put(userSuccess(response.data.user));
		// yield put(openPopUpGoogle2FaSuccess(true));
		yield put(openPopUpSms2FaSuccess(true));

		// notificationContainer(String('Google 2fa successfully activated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/security`);
		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITotpResponse = yield call(api.settings.disable2fa, payload);
		yield put(disable2faSuccess());
		yield put(userSuccess(response.data.user));
		yield put(openPopUpGoogle2FaSuccess(true));

		// notificationContainer(String('Google 2fa successfully turned off!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/security`);
		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* changeUserPassWorker(action: PayloadAction<ICreatePasswordFormData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.changePassword, payload);
		yield put(authInitState());

		notificationContainer(String('Your password has been successfully changed!'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSmsCodeWorker(action: PayloadAction<IGetSmsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.getSmsCode, payload);
		yield put(open2FaPageSuccess(E2FaMassage.SMS));
		notificationContainer(String('A code has been sent to your phone number'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSmsCode2FaLoginWorker(action: PayloadAction<IGetSmsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.getSmsCode2faLogin, payload);
		yield put(getSmsCode2faLoginSuccess());
		notificationContainer(String('A code has been sent to your phone number'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faSmsWorker(action: PayloadAction<I2faSmsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITotpResponse = yield call(api.settings.enable2faSms, payload.data);
		yield put(enable2faSmsPhoneSuccess());
		yield put(userSuccess(response.data.user));
		yield put(openPopUpSms2FaSuccess(true));

		payload.setOpen2FaPhone(false);
		// notificationContainer(String('Sms 2fa successfully activated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/security`);
		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disable2faSmsWorker(action: PayloadAction<I2faSmsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITotpResponse = yield call(api.settings.disable2faSms, payload.data);
		yield put(disable2faSmsPhoneSuccess());
		yield put(userSuccess(response.data.user));
		yield put(openPopUpGoogle2FaSuccess(true));

		payload.setOpen2FaPhone(false);
		// notificationContainer(String('Sms 2fa successfully deactivated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/security`);
		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* checkUserPassWorker(action: PayloadAction<ICheckPassRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICheckPassSagaResponse = yield call(api.settings.checkPassword, payload);
		yield put(checkPassSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getPdfWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.settings.getPdf, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* postPdfWorker() {
	try {
		const response: { data: { url_download: string } } = yield call(api.settings.postPdf);
		console.log(response.data.url_download);
		yield put(postPdfResponse(response.data.url_download));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* settingsSaga() {
	yield takeEvery(editUserRequest.type, editUserWorker);
	yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	yield takeEvery(enable2faRequest.type, enable2faWorker);
	yield takeEvery(disable2faRequest.type, disable2faWorker);
	yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	yield takeEvery(getSmsCodeRequest.type, getSmsCodeWorker);
	yield takeEvery(getSmsCode2faLoginRequest.type, getSmsCode2FaLoginWorker);
	yield takeEvery(enable2faSmsPhoneRequest.type, enable2faSmsWorker);
	yield takeEvery(disable2faSmsPhoneRequest.type, disable2faSmsWorker);
	yield takeEvery(checkPassRequest.type, checkUserPassWorker);
	yield takeEvery(postPdfRequest.type, postPdfWorker);
	yield takeEvery(getPdfRequest.type, getPdfWorker);
}
