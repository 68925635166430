/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IValidationSchemaSellYuorProperty } from 'components/Forms/FormSellYourProperty/types';
import { IData } from 'pages/PropertyDetails';
import { IAddNewOrderrequest, IOrderState } from './types';

export const initialState: IOrderState = {
	orderLoader: false,
};

const orders = createSlice({
	name: '@@orders',
	initialState,
	reducers: {
		postOrdersSellRequest: (state, action: PayloadAction<IData>) => {
			const editUserState = state;
			editUserState.orderLoader = true;
		},

		postOrdersSellSuccess: (state) => {
			const editUserState = state;
			editUserState.orderLoader = false;
		},

		postOrdersBuyRequest: (state, action: PayloadAction<IData>) => {
			const editUserState = state;
			editUserState.orderLoader = true;
		},

		postOrdersBuySuccess: (state) => {
			const editUserState = state;
			editUserState.orderLoader = false;
		},

		postAddOrdersRequest: (state, action: PayloadAction<IAddNewOrderrequest>) => {
			const editUserState = state;
			editUserState.orderLoader = true;
		},

		postAddOrdersSuccess: (state) => {
			const editUserState = state;
			editUserState.orderLoader = false;
		},

		ordersInitState: (state) => {
			const marketInitState = state;
			marketInitState.orderLoader = false;
		},
	},
});

export default orders.reducer;
export const {
	postOrdersSellRequest,
	postOrdersSellSuccess,
	postOrdersBuyRequest,
	postOrdersBuySuccess,
	postAddOrdersRequest,
	postAddOrdersSuccess,
	ordersInitState,
} = orders.actions;
