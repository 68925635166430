import moment from 'moment';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { subscribeToNewsletterRequest } from 'redux/reducers/notifications/reducer';
import { ELinksName } from 'services/constants/listLinks';
import {
	SvgFooterButton,
	SvgFooterMail,
	SvgFooterSocialInstagram,
	SvgFooterSocialMeta,
	SvgFooterSocialTwitter,
} from 'ui/svg-img/svg-footer';
import logoFooter from '../../assets/images/Logo-grey.svg';

export const Footer: FC = () => {
	const [email, setEmail] = useState('');
	const dispatch = useDispatch();

	const handleScroll = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleInput = (event: React.ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setEmail(target.value);
	};

	const handleSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		dispatch(subscribeToNewsletterRequest({ email }));
		setEmail('');
	};

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer-content">
					<div className="footer-top">
						<div className="footer-nav ">
							<ul>
								<li>
									<Link to={ELinksName.TERMS_OF_SERVICE}>Terms of service</Link>
								</li>
								<li>
									<Link to={ELinksName.PRIVACY_POLICY}>Privacy Policy</Link>
								</li>
								<li>
									<Link to={ELinksName.FAQ}>FAQ</Link>
								</li>
								{/* <li>
									<Link to={ELinksName.AML_POLICY}>AML Policy</Link>
								</li> */}
								<li>
									<Link to={ELinksName.HELP}>Support</Link>
								</li>
							</ul>
						</div>

						<div className="footer-top__right">
							<div className="footer-text">
								<h2>Don’t miss new properties!</h2>
								<p>Join our mailing list to be notified when they are posted</p>
							</div>
							<form onSubmit={handleSubmit} action="/" className="footer-form">
								<div className="input">
									<div className="input-wrapper">
										<input
											onChange={handleInput}
											className="input-item input-item--small-height input-item--left-icon"
											type="email"
											placeholder="Your Email"
											required
											value={email}
										/>
										<span className="input-icon">
											<SvgFooterMail />
										</span>
									</div>
								</div>
								<button type="submit" className="button button--action-type">
									<span className="button-icon">
										<SvgFooterButton />
									</span>
								</button>
							</form>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="footer-bottom-left">
							<button onClick={handleScroll} type="button" className="footer-logo">
								<img src={logoFooter} alt="" />
							</button>
							<div className="footer-bottom-group">
								<div className="footer-address">
									<div className="footer-address__icon">
										<svg
											width="12"
											height="16"
											viewBox="0 0 12 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M11.3682 6.80592C11.3682 10.9814 5.99977 14.5603 5.99977 14.5603C5.99977 14.5603 0.631348 10.9814 0.631348 6.80592C0.631348 5.38213 1.19695 4.01665 2.20372 3.00987C3.2105 2.0031 4.57597 1.4375 5.99977 1.4375C7.42356 1.4375 8.78904 2.0031 9.79582 3.00987C10.8026 4.01665 11.3682 5.38213 11.3682 6.80592Z"
												stroke="black"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.99992 8.59457C6.98822 8.59457 7.7894 7.7934 7.7894 6.8051C7.7894 5.8168 6.98822 5.01562 5.99992 5.01562C5.01162 5.01562 4.21045 5.8168 4.21045 6.8051C4.21045 7.7934 5.01162 8.59457 5.99992 8.59457Z"
												stroke="black"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
									<p>99 Hudson Street, 5th Floor, New York, NY 10013</p>
								</div>
								<div className="copyright">
									<p>{`© ${moment().format('YYYY')} BrickFi, Inc. All rights reserved`}</p>
								</div>
							</div>
						</div>
						<div className="footer-social">
							<a href="/" className="footer-social__item">
								<span className="footer-social__item-icon">
									<SvgFooterSocialMeta />
								</span>
								Meta
							</a>
							<a href="/" className="footer-social__item">
								<span className="footer-social__item-icon">
									<SvgFooterSocialTwitter />
								</span>
								Twitter
							</a>
							<a href="/" className="footer-social__item">
								<span className="footer-social__item-icon">
									<SvgFooterSocialInstagram />
								</span>
								Instagram
							</a>
						</div>
					</div>
					<div className="footer-extra">
						<p>
							All investments involve risk and the past performance of a security or financial
							product does not guarantee future results or returns. Keep in mind that while
							diversification may help spread risk it does not assure a profit or protect against
							loss in a down market. There is always the potential of losing money when you invest
							in securities or other financial products. Investors should consider their investment
							objectives and risks carefully before investing. You may obtain a copy of the most
							recent version of the offering documents from&nbsp;
							<a
								href="https://www.sec.gov/cgi-bin/browse-edgar?company=brickfi&match=starts-with&filenum=&State=&Country=&SIC=&myowner=exclude&action=getcompany"
								target="_blank"
								rel="noreferrer"
							>
								the&nbsp;U.S. Securities and Exchange Commission.
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};
