import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/reducers/auth/selectors';
import { SvgSelectArrow } from 'ui/svg-img/SvgHeader';
import { DropDown } from './DropDown';

export const PopUpMenu: FC = () => {
	const [openPopUp, setOpenPopUp] = useState<boolean>(false);
	const [subBtn, setSubBtn] = useState('');
	const { innerBorderRef } = useOnOutsideClick(() => {
		setSubBtn('');
		setOpenPopUp(false);
	});
	const userData = useSelector(getUser);

	const handlePopUp = () => {
		setOpenPopUp(!openPopUp);
	};

	return (
		<div ref={innerBorderRef} className="authorization-btns">
			<div className="select">
				<button onClick={handlePopUp} type="button" className="select__current">
					<span className="select-icon select-icon--color">
						<SvgSelectArrow />
					</span>
					{userData?.name}
					<span className="select__current-arrow">
						<svg
							width="14"
							height="8"
							viewBox="0 0 14 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
								fill="#25252E"
							/>
						</svg>
					</span>
				</button>
				<DropDown
					subBtn={subBtn}
					setSubBtn={setSubBtn}
					handlePopUp={handlePopUp}
					openPopUp={openPopUp}
				/>
			</div>
		</div>
	);
};
