/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotifications } from '../sockets/types';
import {
	INotificationsRequest,
	INotificationsResponse,
	INotificationsState,
	ISubscribeRequest,
} from './types';

export const initialState: INotificationsState = {
	notifications: null,
	notificationsLoader: false,
	subscribeLoader: false,
	countUnread: null,
};

const notifications = createSlice({
	name: '@@notifications',
	initialState,
	reducers: {
		getNotificationsRequest: (state, action: PayloadAction<INotificationsRequest>) => {
			const notificationsState = state;
			notificationsState.notificationsLoader = true;
		},

		getNotificationsSuccess: (state, action: PayloadAction<INotificationsResponse>) => {
			const notificationsState = state;
			const { payload } = action;
			notificationsState.notificationsLoader = false;
			notificationsState.notifications = payload;
		},

		subscribeToNewsletterRequest: (state, action: PayloadAction<ISubscribeRequest>) => {
			const notificationsState = state;
			notificationsState.subscribeLoader = true;
		},
		getUnread: (state, action: PayloadAction<INotifications>) => {
			const notificationsState = state;
			const { payload } = action;
			if (notificationsState.notifications) {
				notificationsState.notifications.data = payload.notifications;
				notificationsState.countUnread = payload.count_unread;
			}
		},

		unsubscribeToNewsletterRequest: (state) => {
			const notificationsState = state;
			notificationsState.subscribeLoader = true;
		},

		notificationsInitState: (state) => {
			const marketInitState = state;
			marketInitState.notificationsLoader = false;
			marketInitState.subscribeLoader = false;
		},
	},
});

export default notifications.reducer;
export const {
	getNotificationsRequest,
	getNotificationsSuccess,
	subscribeToNewsletterRequest,
	unsubscribeToNewsletterRequest,
	getUnread,
	notificationsInitState,
} = notifications.actions;
