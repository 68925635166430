import { FC, lazy, Suspense, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import { routesList } from 'routes/routesList';
import { languages } from 'languages';
// import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { ELinksName } from 'services/constants/listLinks';
import { MainMobileMenu } from 'components/MainMobileMenu';
import { notShow } from 'services/constants/showFooter';
import { hideTheFooterSelector } from 'redux/reducers/auth/selectors';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { HeaderNew } from 'components/HeaderNew';

const Home = lazy(() => import('../../pages/Home'));

// ==========================================:
const App: FC = () => {
	const language = useSelector(appLanguage);
	L.setTexts(languages[language]);
	const [burger, setBurger] = useState(false);
	const { pathname } = useLocation();
	const hideFooter = useSelector(hideTheFooterSelector);

	const activBurger = () => {
		setBurger(!burger);
	};

	return (
		<Suspense fallback={<span>Loading</span>}>
			<BrowserRouter>
				<SocketConnectWrapper>
					<SocketWithPrivateTopicConnectWrapper topicName="private-notifications">
						<div className="wrapper">
							{/* {sellPropertyLoader && <Loader />} */}

							<HeaderNew activBurger={activBurger} burger={burger} />
							{burger && <MainMobileMenu activBurger={activBurger} burger={burger} />}
							<main className={`main ${hideFooter ? 'main-custom' : ''}`}>
								<Switch>
									<Route exact path={ELinksName.HOME} component={Home} />
									{routesList?.map((route) => (
										<Route key={route.path} exact path={route.path} component={route.component} />
									))}
									<Redirect to="/" />
								</Switch>
							</main>
							{(notShow.includes(pathname.replace(/[/\d]/g, '')) && hideFooter) ||
							hideFooter ? null : (
								<Footer />
							)}
						</div>
					</SocketWithPrivateTopicConnectWrapper>
				</SocketConnectWrapper>
			</BrowserRouter>
		</Suspense>
	);
};

export default App;
