import { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { SvgHelp, SvgLogout, SvgSettings, SvgWallet } from 'ui/svg-img/SvgHeader';
import { useDispatch } from 'react-redux';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/listLinks';
import { putStateUsa, putStatus, putTypeNumber } from 'redux/reducers/market/reducer';
import { IPropsDropDown } from './types';
import { SubDropDown } from './SubDropDown';

export const DropDown: FC<IPropsDropDown> = ({ subBtn, setSubBtn, handlePopUp, openPopUp }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handelLogout = () => {
		dispatch(putTypeNumber(0));
		dispatch(putStatus(0));
		dispatch(putStateUsa(''));
		dispatch(logoutRequest({ history }));
	};

	const handleButton = (event: MouseEvent) => {
		const target = event.target as HTMLInputElement;
		if (subBtn === target.name) {
			setSubBtn('');
			return;
		}
		setSubBtn(target.name);
	};

	return (
		<div
			className={classNames('select__drop', {
				'select__drop-activ': openPopUp,
			})}
		>
			<div className="select__drop-scroll">
				<div className="select__drop-item">
					<ul>
						<li
							className={classNames('wallet', {
								'activ-wallet': subBtn === 'wallet',
							})}
						>
							<button
								className={classNames('button-transform', {
									'button-activ': subBtn === 'wallet',
								})}
								onClick={handleButton}
								name="wallet"
								type="button"
							>
								<span className="select-icon select-icon--dark">
									<SvgWallet />
								</span>
								Wallet
								<span className="select-icon select-icon--arrow">
									<svg
										width="14"
										height="8"
										viewBox="0 0 14 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
											fill="#25252E"
										/>
									</svg>
								</span>
							</button>
							<SubDropDown handlePopUp={handlePopUp} subBtn={subBtn} setSubBtn={setSubBtn} />
						</li>
						<li>
							<Link onClick={handlePopUp} to={ELinksName.SETTINGS}>
								<span className="select-icon select-icon--dark ">
									<SvgSettings />
								</span>
								Settings
							</Link>
						</li>
						<li>
							<Link onClick={handlePopUp} to={ELinksName.HELP}>
								<span className="select-icon select-icon--dark">
									<SvgHelp />
								</span>
								Help
							</Link>
						</li>
						<li>
							<button onClick={handelLogout} type="button">
								<span className="select-icon select-icon--dark">
									<SvgLogout />
								</span>
								Logout
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
