/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	IAddPicturesResponse,
	IValidationSchemaSellYuorProperty,
} from 'components/Forms/FormSellYourProperty/types';
import {
	addComplitedRequest,
	addWishlistId,
	checkWishlistRequest,
	checkWishlistSuccess,
	createInvestOrSing,
	getCalculateAmountRequest,
	getCalculateAmountSuccess,
	getCountriesRequest,
	getCountriesResponse,
	getPropertyDetailsRequest,
	getPropertyDetailsSuccess,
	getPropertyRequest,
	getPropertySuccess,
	getSingDocumentsRequest,
	getSingDocumentsResponse,
	getStatesRequest,
	getStatesSuccess,
	getTokenPraceRequest,
	getTokenPraceSuccess,
	marketInitState,
	postWaitListRequest,
	postWaitListResponse,
	removeWishlistIdRequest,
	removeWishlistIdSuccess,
	sellYourPropertyRequest,
	sellYourPropertySuccess,
	wishlistAddRequest,
} from './reducer';
import {
	IDocumentComplitedRequest,
	IDocuSingResponse,
	IPropertyDetailsRequest,
	IPropertyDetailsResponse,
	IPropertyRequest,
	IPropertyResponse,
	ISingDocumentsReques,
	IStatesResponse,
	IWaitListPostRequestReducer,
	IWaitListRequest,
	IWaitlistResponse,
	IWishListResponse,
} from './types';
import { ICountriesResponse } from '../auth/types';

function* sellYourPropertyWorker(action: PayloadAction<IValidationSchemaSellYuorProperty>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IAddPicturesResponse } = yield call(api.market.sellProperty, {
			...payload.data,
			files: payload.data.files?.slice(0, 1) || [],
		});
		yield put(sellYourPropertySuccess());
		if (payload.data.files && payload.data.files.length > 2) {
			for (let i = 1; Number(i) < payload.data.files.length; i++) {
				yield call(api.market.addPictures, {
					files: [payload.data.files[Number(i)]],
					property_id: response.data.property_id,
				});
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				yield payload.count((prev) => +prev + 1);
			}
		}

		yield payload.close(false);
		yield payload.setOpenSuccess(true);
		yield payload.count(1);
	} catch (error) {
		yield put(sellYourPropertySuccess());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getPropertyWorker(action: PayloadAction<IPropertyRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IPropertyResponse } = yield call(api.market.getProperty, payload);
		yield put(getPropertySuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* getPropertyDetailsWorker(action: PayloadAction<IPropertyDetailsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IPropertyDetailsResponse } = yield call(
			api.market.getPropertyDetails,
			payload,
		);

		yield put(getPropertyDetailsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* postWaitListWorker(action: PayloadAction<IWaitListPostRequestReducer>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IWaitlistResponse } = yield call(api.market.postWaitList, payload.body);
		console.log(response.data);
		yield put(postWaitListResponse(response.data.estate));
		yield notificationContainer('Your investment reservation was placed on the waitlist', 'info');
		yield payload.closeModal();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* wishlistAddWorker(action: PayloadAction<IWaitListRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.market.wishlistAdd, payload);
		yield notificationContainer('The property has been added to the wishlist', 'info');
		yield put(addWishlistId(payload.estate_id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* getTokenPraceWorker() {
	try {
		yield put(showLoading());
		const response: { data: number } = yield call(api.market.getTokenPrice);
		yield put(getTokenPraceSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* getCalculateAmountWorker(action: PayloadAction<number>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: number } = yield call(api.market.getCalculateAmount, payload);
		yield put(getCalculateAmountSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* checkWishlistWorker() {
	try {
		yield put(showLoading());
		const response: { data: IWishListResponse } = yield call(api.market.getIdWishList);
		yield put(checkWishlistSuccess(response.data.items));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* removeWishlistIdWorker(action: PayloadAction<number>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.market.removeWishlistId, payload);
		yield put(removeWishlistIdSuccess(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(marketInitState());
	}
}

function* getSingDocumentsWorker(action: PayloadAction<ISingDocumentsReques>) {
	const { payload } = action;

	try {
		const response: { data: IDocuSingResponse } = yield call(api.market.getSingDocuments, payload);
		yield payload.onOpenSing(true);
		yield payload.onOpenSuccess(true);
		if (response.data.next_document && payload?.invest) yield payload.invest(true);
		yield put(getSingDocumentsResponse(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(marketInitState());
	}
}

function* createInvestOrSingWorker() {
	try {
		const response: { data: IDocuSingResponse } = yield call(api.market.createInvestOrSing);
		yield put(getSingDocumentsResponse(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(marketInitState());
	}
}

function* addComplitedWorker(action: PayloadAction<IDocumentComplitedRequest>) {
	const { payload } = action;

	try {
		const response: { data: IDocuSingResponse } = yield call(api.market.addComplited, payload);
		yield put(getSingDocumentsResponse(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(marketInitState());
	}
}

function* getCountriesWorker() {
	try {
		const response: { data: ICountriesResponse } = yield call(api.auth.getCountries);
		yield put(getCountriesResponse(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(marketInitState());
	}
}

function* getStatesWorker() {
	try {
		const response: { data: { countries: Array<IStatesResponse> } } = yield call(
			api.market.getStates,
		);
		yield put(getStatesSuccess(response.data.countries));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(marketInitState());
	}
}

export function* marketSaga() {
	yield takeEvery(sellYourPropertyRequest.type, sellYourPropertyWorker);
	yield takeEvery(getPropertyRequest.type, getPropertyWorker);
	yield takeEvery(getPropertyDetailsRequest.type, getPropertyDetailsWorker);
	yield takeEvery(postWaitListRequest.type, postWaitListWorker);
	yield takeEvery(wishlistAddRequest.type, wishlistAddWorker);
	yield takeEvery(getTokenPraceRequest.type, getTokenPraceWorker);
	yield takeEvery(getCalculateAmountRequest.type, getCalculateAmountWorker);
	yield takeEvery(checkWishlistRequest.type, checkWishlistWorker);
	yield takeEvery(removeWishlistIdRequest.type, removeWishlistIdWorker);
	yield takeEvery(getSingDocumentsRequest.type, getSingDocumentsWorker);
	yield takeEvery(createInvestOrSing.type, createInvestOrSingWorker);
	yield takeEvery(addComplitedRequest.type, addComplitedWorker);
	yield takeEvery(getCountriesRequest.type, getCountriesWorker);
	yield takeEvery(getStatesRequest.type, getStatesWorker);
}
