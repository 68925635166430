import { AxiosError } from 'axios';
import L from 'i18n-react';
import { notificationContainer } from 'services/utils/notificationContainer';
import { customResponseErrors } from './customResponseError/customResponseErrors';

// ==========================================:
const responseErrors = (error: AxiosError) => {
	const { response } = error;
	if (!response) {
		notificationContainer(String(L.translate(`server_error`)), 'error');
	}
	if (response) {
		const { status, data } = response;
		const errorList: Array<string> = [];
		if (data?.message) {
			errorList.push(data?.message);
		} else if (Array.isArray(data?.error)) {
			data?.error &&
				Object.keys(data?.error)?.forEach((item) =>
					errorList?.push(data?.error[item as keyof typeof data.error]),
				);
		} else {
			data?.error && errorList.push(data?.error);
		}

		const errorMessage = String(L.translate(`${errorList.flat()[0]}`));
		switch (status) {
			case 400: // Bad request
				notificationContainer(errorMessage, 'error');
				break;

			// case 403: // Form validation
			// 	notificationContainer(errorMessage, 'error');
			// 	break;

			case 404: // Not found
				notificationContainer(errorMessage, 'error');
				break;
			case 422: // Form validation
				customResponseErrors(errorMessage, 'info', response);
				break;

			case 423: // Not found
				notificationContainer(errorMessage, 'error');
				break;

			case 429: // Too Many Attempts
				notificationContainer(String(L.translate(`too_many_requests`)), 'error');
				break;

			case 500: // Internal Server Error
				notificationContainer(String(L.translate(`server_error`)), 'error');
				break;

			default:
				break;
		}
	}
};

export { responseErrors };
