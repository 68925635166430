import axios, { AxiosInstance } from 'axios';
import onSuccess from './interceptor/onSuccess';
import onError from './interceptor/onError';

const WEB_API_URL =
	process.env.NODE_ENV === 'development'
		? process.env.REACT_APP_WEB_API_URL
		: `${window.location.origin}/api`;

// ==========================================:
const http: AxiosInstance = axios.create({
	baseURL: WEB_API_URL,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

const httpUpload: AxiosInstance = axios.create({
	baseURL: WEB_API_URL,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'multipart/form-data',
		Accept: 'application/json',
	},
});

httpUpload.interceptors.request.use((config) => {
	return onSuccess(config);
});
httpUpload.interceptors.response.use((response) => response, onError);

http.interceptors.request.use(onSuccess);
http.interceptors.response.use((response) => response, onError);

export { http, httpUpload };
