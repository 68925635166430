/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IActiveOrdersResponse,
	IInvestmentsResponse,
	IOrderModifyRequest,
	IOrderModifyResponse,
	IPortfolioState,
	IWishlist,
	IWishlistRequest,
} from './types';

export const initialState: IPortfolioState = {
	wishlist: null,
	portfolioLoader: false,
	investments: null,
	activeOrders: null,
};

const portfolio = createSlice({
	name: '@@portfolio',
	initialState,
	reducers: {
		getWishlistRequest: (state, action: PayloadAction<IWishlistRequest>) => {
			const wishlistState = state;
			wishlistState.portfolioLoader = true;
		},

		getWishlistSuccess: (state, action: PayloadAction<IWishlist>) => {
			const { payload } = action;
			const wishlistState = state;
			wishlistState.portfolioLoader = false;
			wishlistState.wishlist = payload;
		},

		deleteWishlistRequest: (state, action: PayloadAction<number>) => {
			const wishlistState = state;
			wishlistState.portfolioLoader = true;
		},

		deleteWishlistSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const wishlistState = state;
			wishlistState.portfolioLoader = false;
			if (wishlistState.wishlist) {
				wishlistState.wishlist.data = wishlistState.wishlist.data.filter(
					(property) => property.id !== payload,
				);
			}
		},

		getInvestmentsRequest: (state, action: PayloadAction<IWishlistRequest>) => {
			const wishlistState = state;
			wishlistState.portfolioLoader = true;
		},

		getInvestmentsSuccess: (state, action: PayloadAction<IInvestmentsResponse>) => {
			const { payload } = action;
			const wishlistState = state;
			wishlistState.portfolioLoader = false;
			wishlistState.investments = payload;
		},

		getActiveOrdersRequest: (state, action: PayloadAction<IWishlistRequest>) => {
			const wishlistState = state;
			wishlistState.portfolioLoader = true;
		},

		getActiveOrdersSuccess: (state, action: PayloadAction<IActiveOrdersResponse>) => {
			const { payload } = action;
			const wishlistState = state;
			wishlistState.portfolioLoader = false;
			wishlistState.activeOrders = payload;
		},

		cancelActiveOrderRequest: (state, action: PayloadAction<number>) => {},

		cancelActiveOrderSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const wishlistState = state;
			if (wishlistState.activeOrders) {
				wishlistState.activeOrders.data = wishlistState.activeOrders.data.filter(
					(property) => property.id !== payload,
				);
			}
		},

		orderModifyRequest: (state, action: PayloadAction<IOrderModifyRequest>) => {
			const wishlistState = state;
			wishlistState.portfolioLoader = true;
		},

		orderModifySuccess: (state, action: PayloadAction<IOrderModifyResponse>) => {
			const { payload } = action;
			const wishlistState = state;
			wishlistState.portfolioLoader = false;
			if (wishlistState.activeOrders?.data) {
				wishlistState.activeOrders.data = wishlistState.activeOrders?.data.map((order) => {
					if (order.id === payload.order.id) {
						return {
							...order,
							...payload.order,
						};
					}
					return order;
				});
			}
		},

		portfolioInitState: (state) => {
			const portfolioInitState = state;
			portfolioInitState.portfolioLoader = false;
		},
	},
});

export default portfolio.reducer;
export const {
	getWishlistRequest,
	getWishlistSuccess,
	deleteWishlistRequest,
	deleteWishlistSuccess,
	portfolioInitState,
	getInvestmentsRequest,
	getInvestmentsSuccess,
	getActiveOrdersRequest,
	getActiveOrdersSuccess,
	cancelActiveOrderRequest,
	cancelActiveOrderSuccess,
	orderModifyRequest,
	orderModifySuccess,
} = portfolio.actions;
