/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGenerate2faKeyResponse } from 'services/api/settings/types';
import { boolean } from 'yup';
import {
	I2faSmsPayload,
	ICheckPassRequest,
	ICheckPassResponse,
	ICreatePasswordFormData,
	IDisable2faPayload,
	IEditUserRequest,
	IEnable2faPayload,
	IGetSmsLoginRequest,
	IGetSmsRequest,
	IInitialState,
} from './types';

export const initialState: IInitialState = {
	editUser: false,
	data2fa: null,
	account2faLoader: false,
	displayDisable2faForm: false,
	smsCodeLoader: false,
	timerStart: false,
	account2faSmsPhoneLoader: false,
	enable2faSmsPhone: false,
	disable2faSmsPhone: false,
	smsCodeLoginLoader: false,
	checkPassLoader: false,
	checkPass: null,
	open2FaPage: null,
	open2FaSms: false,
	open2FaGoogle: false,
	url_download: '',
};

const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		editUserRequest: (state, action: PayloadAction<IEditUserRequest>) => {
			const editUserState = state;
			editUserState.editUser = true;
		},

		editUserSuccess: (state) => {
			const editUserState = state;
			editUserState.editUser = false;
		},

		generate2faKeyRequest: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
		},
		generate2faKeySuccess: (state, action: PayloadAction<IGenerate2faKeyResponse>) => {
			const { payload } = action;
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.data2fa = payload;
		},

		enable2faRequest: (state, action: PayloadAction<IEnable2faPayload>) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
		},

		enable2faSuccess: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.displayDisable2faForm = false;
		},

		disable2faRequest: (state, action: PayloadAction<IDisable2faPayload>) => {
			const disable2faState = state;

			disable2faState.account2faLoader = true;
		},

		disable2faSuccess: (state) => {
			const disable2faState = state;

			disable2faState.account2faLoader = false;
			disable2faState.data2fa = null;
		},

		enable2faSmsPhoneRequest: (state, action: PayloadAction<I2faSmsPayload>) => {
			const enable2faState = state;

			enable2faState.account2faSmsPhoneLoader = true;
		},

		enable2faSmsPhoneSuccess: (state) => {
			const enable2faState = state;

			enable2faState.account2faSmsPhoneLoader = false;
			enable2faState.enable2faSmsPhone = true;
		},

		disable2faSmsPhoneRequest: (state, action: PayloadAction<I2faSmsPayload>) => {
			const disable2faState = state;

			disable2faState.account2faSmsPhoneLoader = true;
		},

		disable2faSmsPhoneSuccess: (state) => {
			const disable2faState = state;

			disable2faState.account2faSmsPhoneLoader = false;
			disable2faState.disable2faSmsPhone = true;
		},

		getSmsCodeRequest: (state, action: PayloadAction<IGetSmsRequest>) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoader = true;
		},

		getSmsCodeSuccess: (state) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoader = false;
			smsCodeState.timerStart = true;
		},

		getSmsCode2faLoginRequest: (state, action: PayloadAction<IGetSmsLoginRequest>) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoginLoader = true;
		},

		getSmsCode2faLoginSuccess: (state) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoginLoader = false;
		},

		open2FaPageSuccess: (state, action: PayloadAction<string | null>) => {
			const open2FaPageState = state;
			const { payload } = action;
			open2FaPageState.open2FaPage = payload;
		},

		checkPassRequest: (state, action: PayloadAction<ICheckPassRequest>) => {
			const checkPassState = state;

			checkPassState.checkPassLoader = true;
		},

		checkPassSuccess: (state, action: PayloadAction<ICheckPassResponse>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.checkPassLoader = false;
			checkPassState.checkPass = payload;
		},

		openPopUpSms2FaSuccess: (state, action: PayloadAction<boolean>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.open2FaSms = payload;
		},

		openPopUpGoogle2FaSuccess: (state, action: PayloadAction<boolean>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.open2FaGoogle = payload;
		},

		changeUserPassRequest: (state, action: PayloadAction<ICreatePasswordFormData>) => {},
		getPdfRequest: (state, action: PayloadAction<string>) => {},
		postPdfRequest: (state) => {},
		postPdfResponse: (state, { payload }: PayloadAction<string>) => {
			const settingsState = state;
			settingsState.url_download = payload;
		},

		settingsInitState: () => initialState,
	},
});

export default settings.reducer;
export const {
	editUserRequest,
	editUserSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	settingsInitState,
	disable2faSuccess,
	disable2faRequest,
	enable2faSuccess,
	enable2faRequest,
	changeUserPassRequest,
	getSmsCodeRequest,
	getSmsCodeSuccess,
	enable2faSmsPhoneRequest,
	enable2faSmsPhoneSuccess,
	disable2faSmsPhoneRequest,
	disable2faSmsPhoneSuccess,
	getSmsCode2faLoginRequest,
	getSmsCode2faLoginSuccess,
	open2FaPageSuccess,
	checkPassRequest,
	checkPassSuccess,
	openPopUpSms2FaSuccess,
	openPopUpGoogle2FaSuccess,
	postPdfRequest,
	getPdfRequest,
	postPdfResponse,
} = settings.actions;
