import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { ISagaDepFitReq } from 'components/WalletsComponents/type';
import { notificationContainer } from 'services/utils/notificationContainer';
import { ELinksName } from 'services/constants/listLinks';
import { ETypePopUp } from 'services/constants/type-pop-up';
import {
	ICalculateRequest,
	ICalculateResponse,
	ICommisionCalculate,
	ICommisionCalculateResponse,
	ICommisionResponse,
	IConvertRequest,
	IConvertResponse,
	ICreateUsdcRequest,
	ICreateUsdcResponse,
	IDHistoryResponse,
	IDividendsAllResponse,
	IHistoryConvertsResponse,
	IPaginationRequest,
	ITokensCurrentResponse,
	IWalletsHistoryRequest,
	IWalletsHistoryResponse,
	IWalletsResponse,
	IWithdrawUsdcRequest,
	TLimitResponse,
} from './types';
import {
	calculateRequest,
	calculateSuccess,
	commisionCalculateRequest,
	commisionCalculateSuccess,
	commisionRequest,
	commisionSuccess,
	convertRequest,
	convertSuccess,
	createUsdcWalletRequest,
	createUsdcWalletSuccess,
	createUsdWalletRequest,
	createUsdWalletSuccess,
	dividendsAllRequest,
	dividendsAllSuccess,
	dividendsHistoryRequest,
	dividendsHistorySuccess,
	getCurrentBalanceTokensRequest,
	getCurrentBalanceTokensSuccess,
	getHistoryConvertsReguest,
	getHistoryConvertsSuccess,
	getInvestmentsAllReguest,
	getInvestmentsAllSuccess,
	limitRequest,
	limitSuccess,
	walletBalanceRequest,
	walletBalanceSuccess,
	walletInitState,
	walletsHistoryRequest,
	walletsHistorySuccess,
	withdrawUsdcWalletRequest,
	withdrawUsdcWalletSuccess,
} from './reducer';

function* walletsWorker() {
	try {
		yield put(showLoading());
		const response: { data: IWalletsResponse } = yield call(api.wallets.walletBalance);
		yield put(walletBalanceSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* walletsHistoryWorker(action: PayloadAction<IWalletsHistoryRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IWalletsHistoryResponse } = yield call(
			api.wallets.walletHistory,
			payload,
		);
		yield put(walletsHistorySuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createUsdWalletWorker(action: PayloadAction<ISagaDepFitReq>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.wallets.createWallet, payload.data);
		const response: { data: IWalletsResponse } = yield call(api.wallets.walletBalance);
		yield put(walletBalanceSuccess(response.data));
		yield put(createUsdWalletSuccess());
		payload.history.push(ELinksName.CURRENT_BALANCE);
		notificationContainer('Transaction  proceeded successfully', 'success');
	} catch (error) {
		payload.history.push(ELinksName.CURRENT_BALANCE);

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createUsdcWalletWorker(action: PayloadAction<ICreateUsdcRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: ICreateUsdcResponse } = yield call(
			api.wallets.createWalletUsdc,
			payload,
		);

		yield put(createUsdcWalletSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* withdrawUsdcWalletWorker(action: PayloadAction<IWithdrawUsdcRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.wallets.createWalletUsdc, payload.data);

		yield put(withdrawUsdcWalletSuccess());
		payload.openPopUp(ETypePopUp.SUBMIT);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* convertWorker(action: PayloadAction<IConvertRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IConvertResponse = yield call(api.wallets.convert, payload.data);
		payload.firstPopUp(false);
		payload.secondPopUp(true);
		yield put(convertSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* calculateWorker(action: PayloadAction<ICalculateRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: ICalculateResponse } = yield call(api.wallets.calculate, payload);

		yield put(calculateSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* commisionWorker() {
	try {
		yield put(showLoading());
		const response: { data: ICommisionResponse } = yield call(api.wallets.commision);

		yield put(commisionSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* commisionCalculateWorker(action: PayloadAction<ICommisionCalculate>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: ICommisionCalculateResponse } = yield call(
			api.wallets.commisionCalculate,
			payload,
		);

		yield put(commisionCalculateSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* limitWorker() {
	try {
		yield put(showLoading());
		const response: { data: TLimitResponse } = yield call(api.wallets.limit);

		yield put(limitSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* dividendsAllWorker() {
	try {
		yield put(showLoading());
		const response: { data: IDividendsAllResponse } = yield call(api.wallets.dividendsAll);

		yield put(dividendsAllSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* dividendsHistoryWorker(action: PayloadAction<IPaginationRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IDHistoryResponse } = yield call(api.wallets.dividendsHistory, payload);

		yield put(dividendsHistorySuccess(response.data.dividends));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getCurrentBalanceTokensWorker(action: PayloadAction<IPaginationRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: ITokensCurrentResponse } = yield call(
			api.wallets.currentBalanceTokens,
			payload,
		);

		yield put(getCurrentBalanceTokensSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getInvestmentsAllWorker(action: PayloadAction<IWalletsHistoryRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: AxiosResponse = yield call(api.wallets.getInvestmentsAll, payload);
		yield put(getInvestmentsAllSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getHistoryConvertsWorker(action: PayloadAction<IWalletsHistoryRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IHistoryConvertsResponse } = yield call(
			api.wallets.getHistoryConverts,
			payload,
		);
		yield put(getHistoryConvertsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletInitState());
	} finally {
		yield put(hideLoading());
	}
}

export function* walletsSaga() {
	yield takeEvery(walletBalanceRequest.type, walletsWorker);
	yield takeEvery(walletsHistoryRequest.type, walletsHistoryWorker);
	yield takeEvery(createUsdWalletRequest.type, createUsdWalletWorker);
	yield takeEvery(createUsdcWalletRequest.type, createUsdcWalletWorker);
	yield takeEvery(withdrawUsdcWalletRequest.type, withdrawUsdcWalletWorker);
	yield takeEvery(convertRequest.type, convertWorker);
	yield takeEvery(calculateRequest.type, calculateWorker);
	yield takeEvery(commisionRequest.type, commisionWorker);
	yield takeEvery(commisionCalculateRequest.type, commisionCalculateWorker);
	yield takeEvery(limitRequest.type, limitWorker);
	yield takeEvery(dividendsAllRequest.type, dividendsAllWorker);
	yield takeEvery(dividendsHistoryRequest.type, dividendsHistoryWorker);
	yield takeEvery(getCurrentBalanceTokensRequest.type, getCurrentBalanceTokensWorker);
	yield takeEvery(getInvestmentsAllReguest.type, getInvestmentsAllWorker);
	yield takeEvery(getHistoryConvertsReguest.type, getHistoryConvertsWorker);
}
