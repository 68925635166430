/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserSingUpSaga } from 'pages/Auth/SignUp/types';
import {
	IAuthStore,
	ICountriesResponse,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IGenerateSmsRequestPayload,
	IipConfirmPayload,
	ILoginPayload,
	ILoginResponse,
	ILoginResponseUser,
	ILogoutPayload,
	IResetTwoFaPayload,
	IVerificationFilesPayload,
	ISurvObj,
	IUserKYCResponse,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	user: null,
	hideFooter: false,
	clearData: false,
	countries: null,
	emailTrue: false,
	emailMessage: '',
	userToVerify: null,
	surveys: null,
	agreementData: null,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},

		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;

			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.user = payload.user;
			loginState.socketToken = payload.socket_token;
		},

		registrationRequest: (state, action: PayloadAction<IUserSingUpSaga>) => {
			const registrationState = state;

			registrationState.registrLoader = true;
		},

		registrationSuccess: (state) => {
			const registrationState = state;

			registrationState.registrLoader = false;
		},

		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const logoutState = state;

			logoutState.logoutLoader = true;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},

		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},

		createNewPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {
			const createNewPasswordState = state;
			createNewPasswordState.createNewPasswordLoader = true;
		},

		createNewPasswordSuccess: (state) => {
			const createNewPasswordState = state;
			createNewPasswordState.createNewPasswordLoader = false;
		},

		userSuccess: (state, action: PayloadAction<ILoginResponseUser>) => {
			const userState = state;
			const { payload } = action;
			userState.user = payload;
		},

		emailConfirmRequest: (state, action: PayloadAction<IEmailConfirmPayload>) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = true;
		},

		emailConfirmSuccess: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = false;
		},

		emailConfirmStatusRequest: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = true;
		},

		hideTheFooter: (state, action: PayloadAction<boolean>) => {
			const hideTheFooterState = state;
			const { payload } = action;

			hideTheFooterState.hideFooter = payload;
		},

		clearData: (state, action: PayloadAction<boolean>) => {
			const hideTheFooterState = state;
			const { payload } = action;

			hideTheFooterState.clearData = payload;
		},

		getCountriesRequest: (state) => {},

		getCountriesSuccess: (state, action: PayloadAction<ICountriesResponse>) => {
			const { payload } = action;
			const getCountriesState = state;
			getCountriesState.countries = payload;
		},

		checkEmailRequest: (state, action: PayloadAction<{ email: string }>) => {},

		checkEmailSuccess: (state, action: PayloadAction<boolean | null>) => {
			const { payload } = action;
			const getcheckEmailState = state;
			getcheckEmailState.emailTrue = !!payload;
			getcheckEmailState.emailMessage = '';
			if (payload === null) {
				getcheckEmailState.emailMessage = 'The email has already been taken.';
			}
		},

		emailConfirmStatusCallback: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = false;
			emailConfirmState.emailResetConfirmTokenMessage = false;
		},
		emailResetConfirmTokenRequest: (
			state,
			action: PayloadAction<IEmailResetConfirmTokenPayload>,
		) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = true;
		},
		emailResetConfirmTokenSuccess: (state) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = false;
		},
		ipConfirmRequest: (state, action: PayloadAction<IipConfirmPayload>) => {
			const ipConfirmState = state;
			ipConfirmState.ipConfirmLoader = true;
		},
		ipConfirmSuccess: (state) => {
			const ipConfirmState = state;
			ipConfirmState.ipConfirmLoader = false;
		},
		generateSmsRequest: (state, action: PayloadAction<IGenerateSmsRequestPayload>) => {
			const generateSmsState = state;
			generateSmsState.generateSmsLoader = true;
		},
		forgotTwoFaRequest: (state, action: PayloadAction<IResetTwoFaPayload>) => {
			const twoFaState = state;
		},
		verificationFilesRequest: (state, action: PayloadAction<IVerificationFilesPayload>) => {},
		verificationFilesSuccess: (state, action: PayloadAction<IUserKYCResponse>) => {
			const newState = state;
			newState.agreementData = action.payload;
		},
		setUserIdToVerify: (state, action) => {
			const newState = state;
			newState.userToVerify = action.payload;
		},
		getSurveyListRequest: (state) => {},
		getSurveyListSuccess: (state, action: PayloadAction<Array<ISurvObj>>) => {
			const newState = state;
			newState.surveys = action.payload;
		},
		sendSurveyAnswerRequest: (state, action) => {},
		sendUserAgreement: (state, action) => {},
		authInitState: () => initialState,
	},
});

export default auth.reducer;
export const {
	ipConfirmRequest,
	ipConfirmSuccess,
	loginRequest,
	loginSuccess,
	registrationRequest,
	registrationSuccess,
	authInitState,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmSuccess,
	forgotTwoFaRequest,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	emailConfirmStatusRequest,
	emailConfirmStatusCallback,
	generateSmsRequest,
	logoutRequest,
	userSuccess,
	hideTheFooter,
	clearData,
	getCountriesRequest,
	getCountriesSuccess,
	checkEmailRequest,
	checkEmailSuccess,
	verificationFilesRequest,
	verificationFilesSuccess,
	setUserIdToVerify,
	getSurveyListRequest,
	getSurveyListSuccess,
	sendSurveyAnswerRequest,
	sendUserAgreement,
} = auth.actions;
