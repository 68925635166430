import { ETransactionTabsName } from './transaction-history-tabs';

export enum ELinksName {
	HOME = '/',
	LOGIN = '/login',
	REGISTRATION = '/signup',
	CONFIRM_EMAIL = '/confirm_email',
	MARKETPLACE = '/marketplace',
	PORTFOLIO = '/portfolio',
	RESET_PASSWORD = '/reset-password',
	CREATE_NEW_PASSWORD = '/recovery_password',
	SETTINGS = '/settings',
	HELP = '/help',
	CURRENT_BALANCE = '/current-balance',
	TRANSACTION_HISTORY = '/transaction-history',
	CONVERT = '/convert',
	MY_DIVIDENDS = '/my-dividends',
	TOKENS = '/tokens',
	SELL_YOUR_PROPERTY = '/sell-your-property',
	TERMS_OF_SERVICE = '/terms-of-service',
	PRIVACY_POLICY = '/privacy-policy',
	AML_POLICY = '/aml-policy',
	FAQ = '/faq',
	PROPERTY_DETAILS = '/property-details',
	INVEST_NOW = '/invest-now',
	INVEST_COMPLETED = '/finish-investment',
	SUCCESS = '/success',
	SURVEY = '/survey',
	KYC = '/kyc',
	AGREEMENT = '/agreement',
}

export enum EParamsNameSettings {
	PORTFOLIO = 'profile',
	NOTIFICATION = 'notifications',
	VERIFICATION = 'verification',
	MY_DOCUMENTS = 'my-documents',
	SECURITY = 'security',
}

export enum EParamsNameWallet {
	DEPOSIT_FIAT = 'deposit-fiat',
	DEPOSIT_CRYPTO = 'deposit-crypto',
	WITHDRAW_FIAT = 'withdraw-fiat',
	WITHDRAW_CRYPTO = 'withdraw-crypto',
	ADD_CARD = 'add-card',
}

export enum EParamsWallet {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
	ADD_CARD = 'add-card',
	LIMITATION = 'limitations',
	WITHDRAW = 'withdraw',
	DEPOSIT = 'deposit',
}

export enum EParamsPortfolio {
	INVESTED_PROPERTY = 'invested-property',
	WISHLIST = 'wishlist',
	ACTIVE_ORDERS = 'active-orders',
}

export enum EParamsTokens {
	HISTORY_OF_TRADE = 'trading-history',
	CURRENT_BALANCE = 'current-balance',
}

export const listSubLinks = [
	{ link: ELinksName.CURRENT_BALANCE, nameLink: 'Current Balance' },
	{
		link: `${ELinksName.TRANSACTION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsWallet.CRYPTO}`,
		nameLink: 'Transaction History',
	},
	{ link: ELinksName.CONVERT, nameLink: 'Convert' },
	{ link: ELinksName.MY_DIVIDENDS, nameLink: 'My Dividends' },
	{ link: ELinksName.TOKENS, nameLink: 'Tokens' },
];

export const listLinksSettings = [
	{ link: ELinksName.SETTINGS, nameLink: 'Profile', param: EParamsNameSettings.PORTFOLIO },
	{
		link: ELinksName.SETTINGS,
		nameLink: 'Notifications',
		param: EParamsNameSettings.NOTIFICATION,
	},
	{ link: ELinksName.SETTINGS, nameLink: 'Verification', param: EParamsNameSettings.VERIFICATION },
	{ link: ELinksName.SETTINGS, nameLink: 'My documents', param: EParamsNameSettings.MY_DOCUMENTS },
	{ link: ELinksName.SETTINGS, nameLink: 'Security', param: EParamsNameSettings.SECURITY },
];

export enum EParams {
	TAB = '/:tab?',
	SUBTAB = '/:subTab?',
	ID = '/:id?',
	SEARCH = '/:search?',
	PAGE = '/:page?',
}
