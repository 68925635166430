import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { IData } from 'pages/PropertyDetails';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	changeSumTokens,
	editPropertyDetailsSuccess,
	getPropertyDetailsRequest,
	putOrderSuccess,
	removeOrderMargeSuccess,
} from '../market/reducer';
import { IOrders } from '../market/types';
import {
	ordersInitState,
	postAddOrdersRequest,
	postAddOrdersSuccess,
	postOrdersBuyRequest,
	postOrdersSellRequest,
	postOrdersSellSuccess,
} from './reducer';
import { IAddNewOrderrequest } from './types';

function* postOrdersSellWorker(action: PayloadAction<IData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.market.postOrdersSell, payload.id);
		yield getPropertyDetailsRequest({ id: payload.id });
		yield put(postOrdersSellSuccess());
		yield put(changeSumTokens({ sold: payload.amountOfTokens }));
		yield put(editPropertyDetailsSuccess(payload.id));
		yield notificationContainer('Tokens have been successfully sold', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(ordersInitState());
	}
}

function* postOrdersBuyWorker(action: PayloadAction<IData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.market.postOrdersBuy, payload.id);
		yield getPropertyDetailsRequest({ id: payload.id });
		yield put(postAddOrdersSuccess());
		yield notificationContainer('Tokens successfully purchased', 'info');
		yield put(changeSumTokens({ purchased: payload.amountOfTokens }));
		yield put(editPropertyDetailsSuccess(payload.id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(ordersInitState());
	}
}

function* postAddOrdersWorker(action: PayloadAction<IAddNewOrderrequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: { order: IOrders; merged_order_id: number } } = yield call(
			api.market.proposeOrders,
			payload,
		);
		yield put(postAddOrdersSuccess());

		if (response?.data?.order) yield put(putOrderSuccess(response.data.order));
		if (response?.data?.merged_order_id)
			yield put(removeOrderMargeSuccess(response.data.merged_order_id));
		yield payload.isClose(false);
		if (response.data.merged_order_id) {
			yield put(
				changeSumTokens(
					payload.activeTab === 'sell'
						? { sold: payload.data.amount }
						: { purchased: payload.data.amount },
				),
			);
			notificationContainer(
				payload.activeTab === 'sell'
					? `Your order was matched successfully. You have sold ${payload.data.amount} tokens`
					: `Your order was matched successfully. You have purchased ${payload.data.amount} tokens`,
				'success',
			);
		} else {
			yield payload.isOpen(true);
		}
		yield payload.setActiveTab(payload.activeTab === 'sell' ? 'buy' : 'sell');
		yield payload.setType(payload.activeTab === 'sell' ? 2 : 1);
	} catch (error) {
		yield payload.isClose(false);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(ordersInitState());
	}
}

export function* ordersSaga() {
	yield takeEvery(postOrdersSellRequest.type, postOrdersSellWorker);
	yield takeEvery(postOrdersBuyRequest.type, postOrdersBuyWorker);
	yield takeEvery(postAddOrdersRequest.type, postAddOrdersWorker);
}
