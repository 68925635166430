import classNames from 'classnames';
import { IHeaderProps } from 'components/Header/types';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/listLinks';
import { PopUpActivMenu } from 'ui/NavMobile/PopUpActivMenu';

export const MainMobileMenu: FC<IHeaderProps> = ({ activBurger, burger }) => {
	const auth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();
	const [about, setAbout] = useState<boolean>(false);
	const { innerBorderRef } = useOnOutsideClick(() => setAbout(false));

	const heandelLink = (event: MouseEvent) => {
		const target = event.target as HTMLLinkElement;
		history.push(target.href);
	};

	const handleAbout = () => {
		setAbout(!about);
	};

	return (
		<div
			className={classNames('nav-block', {
				active: burger,
			})}
		>
			<div className="nav-block__inner">
				<nav className="main-nav">
					<div onClick={activBurger} className="nav-item">
						<Link to={ELinksName.MARKETPLACE} className="nav-item__link">
							Marketplace
						</Link>
					</div>
					<div onClick={activBurger} className="nav-item">
						<Link to={ELinksName.PORTFOLIO} className="nav-item__link">
							Portfolio
						</Link>
					</div>
					<div
						className={classNames('nav-item', {
							active: about,
						})}
					>
						<div className="mobile-menu" ref={innerBorderRef}>
							<button onClick={handleAbout} type="button" className="nav-item__link">
								About
								<span className="nav-item__link-arrow">
									<svg
										width="14"
										height="8"
										viewBox="0 0 14 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
											fill="#25252E"
										/>
									</svg>
								</span>
							</button>
							<div className="dropdown dropdown--nav">
								<ul className="dropdown-list">
									<li>
										<a
											onClick={(event: MouseEvent) => {
												heandelLink(event);
												handleAbout();
												activBurger();
											}}
											href="#hove-it-work"
										>
											How it works
										</a>
									</li>
									<li>
										<a
											onClick={(event: MouseEvent) => {
												heandelLink(event);
												handleAbout();
												activBurger();
											}}
											href="#who-we-are"
										>
											Who we are
										</a>
									</li>
									{/* <li>
										<a
											onClick={(event: MouseEvent) => {
												heandelLink(event);
												handleAbout();
												activBurger();
											}}
											href="#legal-matters"
										>
											Legal Matters
										</a>
									</li>
									<li>
										<Link
											onClick={() => {
												activBurger();
												handleAbout();
											}}
											to={ELinksName.SELL_YOUR_PROPERTY}
										>
											Sell your property
										</Link>
									</li> */}
									<li>
										<Link
											onClick={() => {
												activBurger();
												handleAbout();
											}}
											to={ELinksName.FAQ}
										>
											FAQ
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>

				{!auth ? (
					<div className="authorization-btns">
						<Link
							onClick={activBurger}
							to={ELinksName.LOGIN}
							className="button button--type2 button--authorization"
						>
							Log In
						</Link>
						<Link
							onClick={activBurger}
							to={ELinksName.REGISTRATION}
							className="button button--authorization"
						>
							Sign Up
						</Link>
					</div>
				) : (
					<PopUpActivMenu activBurger={activBurger} />
				)}
			</div>
		</div>
	);
};
