/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IValidationSchemaSellYuorProperty } from 'components/Forms/FormSellYourProperty/types';
import { ICountriesResponse } from '../auth/types';
import {
	IDocumentComplitedRequest,
	IDocuSingResponse,
	IEstate,
	IMarketState,
	IOrders,
	IPropertyDetailsRequest,
	IPropertyDetailsResponse,
	IPropertyRequest,
	IPropertyResponse,
	ISingDocumentsReques,
	IStatesResponse,
	IWaitlist,
	IWaitListPostRequest,
	IWaitListPostRequestReducer,
	IWaitListRequest,
	TWishListId,
} from './types';

export const initialState: IMarketState = {
	sellPropertyLoader: false,
	propertyAll: null,
	property: null,
	tokenPrace: null,
	calculateAmount: null,
	states: null,
	statesAll: null,
	wishlistId: null,
	docuSing: null,
	typeNumber: null,
	status: null,
	stateUsa: null,
	country: null,
	countries: null,
};

const settings = createSlice({
	name: '@@market',
	initialState,
	reducers: {
		sellYourPropertyRequest: (state, action: PayloadAction<IValidationSchemaSellYuorProperty>) => {
			const editUserState = state;
			editUserState.sellPropertyLoader = true;
		},

		sellYourPropertySuccess: (state) => {
			const editUserState = state;
			editUserState.sellPropertyLoader = false;
		},

		getPropertyRequest: (state, action: PayloadAction<IPropertyRequest>) => {
			const getPropertyState = state;
			getPropertyState.sellPropertyLoader = true;
		},

		getPropertySuccess: (state, action: PayloadAction<IPropertyResponse>) => {
			const getPropertyState = state;
			const { payload } = action;
			getPropertyState.sellPropertyLoader = false;
			getPropertyState.propertyAll = payload.active_estates;
			getPropertyState.states = payload.states;
		},

		getPropertyDetailsRequest: (state, action: PayloadAction<IPropertyDetailsRequest>) => {
			const getPropertyDetailsState = state;
			getPropertyDetailsState.sellPropertyLoader = true;
		},

		getPropertyDetailsSuccess: (state, action: PayloadAction<IPropertyDetailsResponse | null>) => {
			const getPropertyDetailsState = state;
			const { payload } = action;
			getPropertyDetailsState.sellPropertyLoader = false;
			getPropertyDetailsState.property = payload;
		},

		editPropertyDetailsSuccess: (state, action: PayloadAction<number | null>) => {
			const getPropertyDetailsState = state;
			const { payload } = action;
			getPropertyDetailsState.sellPropertyLoader = false;
			if (getPropertyDetailsState.property?.estate.orders) {
				getPropertyDetailsState.property.estate.orders =
					getPropertyDetailsState.property?.estate.orders.filter((order) => order.id !== payload);
			}
		},

		changeSumTokens: (state, action: PayloadAction<{ sold?: number; purchased?: number }>) => {
			const { payload } = action;
			const changeSumTokensState = state;
			if (payload?.purchased && changeSumTokensState.property)
				changeSumTokensState.property.user_invested_tokens_here += payload.purchased;
			if (payload?.sold && changeSumTokensState.property)
				changeSumTokensState.property.user_invested_tokens_here -= payload.sold;
		},

		putOrderSuccess: (state, action: PayloadAction<IOrders>) => {
			const getPropertyDetailsState = state;
			const { payload } = action;
			getPropertyDetailsState.sellPropertyLoader = false;
			if (getPropertyDetailsState.property?.estate.orders) {
				getPropertyDetailsState.property?.estate.orders.unshift(payload);
			}
		},

		removeOrderMargeSuccess: (state, action: PayloadAction<number>) => {
			const getPropertyDetailsState = state;
			const { payload } = action;

			if (getPropertyDetailsState.property?.estate.orders) {
				getPropertyDetailsState.property.estate.orders =
					getPropertyDetailsState.property.estate.orders.filter((order) => order.id !== payload);
			}
		},

		wishlistAddRequest: (state, action: PayloadAction<IWaitListRequest>) => {},

		postWaitListRequest: (state, action: PayloadAction<IWaitListPostRequestReducer>) => {
			const getPropertyDetailsState = state;
			getPropertyDetailsState.sellPropertyLoader = true;
		},

		postWaitListResponse: (state, action: PayloadAction<IEstate>) => {
			const getPropertyDetailsState = state;
			const { payload } = action;
			if (getPropertyDetailsState.property?.estate) {
				getPropertyDetailsState.property.tokensReservedPercentage =
					payload.tokensReservedPercentage;
				getPropertyDetailsState.property.tokensSoldPercentage = payload.tokensSoldPercentage;
			}
		},

		getTokenPraceRequest: (state) => {},

		getTokenPraceSuccess: (state, action: PayloadAction<number | null>) => {
			const getTokenPraceState = state;
			const { payload } = action;
			getTokenPraceState.sellPropertyLoader = false;
			getTokenPraceState.tokenPrace = payload;
		},

		getCalculateAmountRequest: (state, action: PayloadAction<number>) => {},

		getCalculateAmountSuccess: (state, action: PayloadAction<number | null>) => {
			const getCalculateAmountState = state;
			const { payload } = action;
			getCalculateAmountState.sellPropertyLoader = false;
			getCalculateAmountState.calculateAmount = payload;
		},

		checkWishlistRequest: (state) => {},

		checkWishlistSuccess: (state, action: PayloadAction<TWishListId>) => {
			const getWishlistState = state;
			const { payload } = action;
			getWishlistState.wishlistId = payload;
		},

		removeWishlistIdRequest: (state, action: PayloadAction<number>) => {},

		removeWishlistIdSuccess: (state, action: PayloadAction<number>) => {
			const getWishlistState = state;
			const { payload } = action;
			if (getWishlistState.wishlistId) {
				getWishlistState.wishlistId = getWishlistState.wishlistId.filter((id) => id !== payload);
			}
		},

		addWishlistId: (state, action: PayloadAction<number>) => {
			const getWishlistState = state;
			const { payload } = action;

			if (getWishlistState.wishlistId) {
				getWishlistState.wishlistId.push(payload);
			}
		},

		getSingDocumentsRequest: (state, action: PayloadAction<ISingDocumentsReques>) => {
			const singDocumentsState = state;
			singDocumentsState.sellPropertyLoader = true;
		},

		getSingDocumentsResponse: (state, action: PayloadAction<IDocuSingResponse>) => {
			const singDocumentsState = state;
			const { payload } = action;
			singDocumentsState.sellPropertyLoader = false;
			singDocumentsState.docuSing = payload;
		},

		createInvestOrSing: (state) => {},

		addComplitedRequest: (state, action: PayloadAction<IDocumentComplitedRequest>) => {},

		putTypeNumber: (state, action: PayloadAction<number | null>) => {
			const typeNumberState = state;
			const { payload } = action;
			typeNumberState.typeNumber = payload;
		},

		putStatus: (state, action: PayloadAction<number | null>) => {
			const statusState = state;
			const { payload } = action;
			statusState.status = payload;
		},

		putStateUsa: (state, action: PayloadAction<string | null>) => {
			const stateUsaState = state;
			const { payload } = action;
			stateUsaState.stateUsa = payload;
		},

		putCountry: (state, action: PayloadAction<string | null>) => {
			const countryState = state;
			const { payload } = action;
			countryState.country = payload;
		},

		getCountriesRequest: (state) => {},

		getCountriesResponse: (state, action: PayloadAction<ICountriesResponse>) => {
			const countriesState = state;
			const { payload } = action;
			countriesState.countries = payload;
		},

		getStatesRequest: (state) => {},

		getStatesSuccess: (state, action: PayloadAction<Array<IStatesResponse>>) => {
			const { payload } = action;
			const statesUsaState = state;
			statesUsaState.statesAll = payload;
		},

		marketInitState: (state) => {
			const marketInitState = state;
			marketInitState.sellPropertyLoader = false;
		},
	},
});

export default settings.reducer;
export const {
	sellYourPropertyRequest,
	sellYourPropertySuccess,
	getPropertyRequest,
	getPropertySuccess,
	getPropertyDetailsRequest,
	getPropertyDetailsSuccess,
	postWaitListRequest,
	wishlistAddRequest,
	getTokenPraceRequest,
	getTokenPraceSuccess,
	getCalculateAmountRequest,
	putOrderSuccess,
	getCalculateAmountSuccess,
	checkWishlistRequest,
	checkWishlistSuccess,
	removeWishlistIdRequest,
	removeWishlistIdSuccess,
	getSingDocumentsRequest,
	getSingDocumentsResponse,
	postWaitListResponse,
	addWishlistId,
	putTypeNumber,
	putStatus,
	putStateUsa,
	putCountry,
	createInvestOrSing,
	addComplitedRequest,
	editPropertyDetailsSuccess,
	removeOrderMargeSuccess,
	marketInitState,
	changeSumTokens,
	getCountriesRequest,
	getCountriesResponse,
	getStatesRequest,
	getStatesSuccess,
} = settings.actions;
