import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	ICalculateRequest,
	ICreateUsdcRequest,
	IPaginationRequest,
	IWalletsHistoryRequest,
} from 'redux/reducers/wallets/types';
import { IDepositeFiatRequest } from 'components/WalletsComponents/type';
import { IWalletApi } from './types';

export const wallets: IWalletApi = {
	walletBalance: () => http.get(endpoint.wallet.WALLET_BALANCE),
	walletHistory: (payload: IWalletsHistoryRequest) =>
		http.post(endpoint.wallet.WALLET_HISTORY, payload),
	createWallet: (payload: IDepositeFiatRequest) =>
		http.post(endpoint.wallet.CREATE_WALLET, payload),
	createWalletUsdc: (payload: ICreateUsdcRequest) =>
		http.post(endpoint.wallet.CREATE_WALLET, payload),
	convert: (payload: ICalculateRequest) => http.post(endpoint.wallet.CONVERT_WALLET, payload),
	calculate: (payload: ICalculateRequest) => http.post(endpoint.wallet.CALCULATE, payload),
	commision: () => http.get(endpoint.wallet.COMMISION),
	commisionCalculate: (payload) => http.post(endpoint.wallet.COMMISION_CALCULATE, payload),
	limit: () => http.get(endpoint.wallet.LIMITS),
	dividendsAll: () => http.get(endpoint.wallet.DIVIDENDS_ALL),
	dividendsHistory: (params: IPaginationRequest) =>
		http.get(endpoint.wallet.DIVIDENDS_HISTORY, { params }),
	currentBalanceTokens: (params: IPaginationRequest) =>
		http.get(endpoint.wallet.CURRENT_BALANCE, { params }),
	getInvestmentsAll: (params: IWalletsHistoryRequest) =>
		http.get(endpoint.wallet.GET_APARTMENT_INVEST, { params }),
	getHistoryConverts: (params: IWalletsHistoryRequest) =>
		http.get(endpoint.wallet.GET_HISTORY_CONVERTS, { params }),
};
