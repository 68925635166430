import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { IUserSingUpSaga } from 'pages/Auth/SignUp/types';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import L from 'i18n-react';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { E2FaMassage } from 'services/constants/twoFaMassage';
import { ELinksName } from 'services/constants/listLinks';
import { History } from 'history';
import {
	authInitState,
	checkEmailRequest,
	checkEmailSuccess,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmStatusCallback,
	emailConfirmStatusRequest,
	emailConfirmSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	getCountriesRequest,
	getCountriesSuccess,
	getSurveyListRequest,
	getSurveyListSuccess,
	loginRequest,
	loginSuccess,
	logoutRequest,
	registrationRequest,
	registrationSuccess,
	sendSurveyAnswerRequest,
	sendUserAgreement,
	setUserIdToVerify,
	verificationFilesRequest,
	verificationFilesSuccess,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IEmailConfirmPayload,
	ILogoutPayload,
	IForgotPasswordPayload,
	ICreateNewPasswordPayload,
	ICountriesResponse,
	IConfirmResponse,
	IVerificationFilesPayload,
	ISurvObj,
	IUserKYCResponse,
} from './types';
import { open2FaPageSuccess } from '../settings/reducer';
import { userToVerify } from './selectors';

function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILoginResponse | IUserKYCResponse = yield call(api.auth.login, payload.data);
		if (response.error && response.error === 'user_unverified') {
			yield put(setUserIdToVerify(response.user_id));
			// payload.history.push(ELinksName.KYC); // Somehow it doesn't work
			window.location.href = ELinksName.KYC;
		} else if (response.error && response.error === 'start_survey') {
			yield put(setUserIdToVerify(response.user_id));
			// payload.history.push(ELinksName.SURVEY); // Somehow it doesn't work
			window.location.href = ELinksName.SURVEY;
		} else if (response.error && response.error === 'sign_agreement') {
			yield put(verificationFilesSuccess(response as IUserKYCResponse));
			// payload.history.push(ELinksName.AGREEMENT); // Somehow it doesn't work
			window.location.href = ELinksName.AGREEMENT;
		} else {
			yield put(loginSuccess(response as ILoginResponse));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const { response } = error;
			if (
				response?.data.message?.includes(E2FaMassage.GOOGLE) ||
				response?.data.error.includes(E2FaMassage.GOOGLE)
			) {
				notificationContainer(`${String(L.translate('the_totp_field_is_required'))}`, 'info');
				yield put(open2FaPageSuccess(response?.data.error));
				return;
			}

			if (
				response?.data.message?.includes(E2FaMassage.SMS) ||
				response?.data.error.includes(E2FaMassage.SMS)
			) {
				yield put(open2FaPageSuccess(response?.data.error));
				return;
			}

			responseErrors(error);
			return;
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* registrationRequestWorker(action: PayloadAction<IUserSingUpSaga>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.registration, payload.data);
		yield put(registrationSuccess());

		yield payload.history.push(`${ELinksName.SUCCESS}/${String(payload.data.email)}`);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* emailConfirmRequestWorker(action: PayloadAction<IEmailConfirmPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IConfirmResponse = yield call(api.auth.emailConfirm, payload);
		yield put(setUserIdToVerify(response.user_id));
		if (response.page === 'kyc') {
			yield payload.history.push(ELinksName.KYC);
		}
		if (response.page === 'survey') {
			yield payload.history.push(ELinksName.SURVEY);
		}
		yield put(emailConfirmStatusCallback());
		yield put(emailConfirmSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* logoutRequestWorker(action: PayloadAction<ILogoutPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push({
			search: '',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, payload);
		yield put(forgotPasswordSuccess());

		notificationContainer(
			`Please check your email <span>${payload.email},</span> we have sent a link to reset password.`,
			'success',
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* createNewPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, payload.data);
		yield put(createNewPasswordSuccess());
		payload.history.push('/login');

		notificationContainer(String(L.translate(`password_successfully_changed`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getCountriesWorker() {
	try {
		const response: { data: ICountriesResponse } = yield call(api.auth.getCountries);
		yield put(getCountriesSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	}
}

function* checkEmailWorker(action: PayloadAction<{ email: string }>) {
	const { payload } = action;

	try {
		yield call(api.auth.checkEmail, payload);
		yield put(checkEmailSuccess(true));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(checkEmailSuccess(null));
			// responseErrors(error);
		}
		// yield put(authInitState());
	}
}

function* verificationFilesWorker(action: PayloadAction<IVerificationFilesPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(userToVerify);
		const response: IUserKYCResponse = yield call(api.auth.sendVerificationFiles, {
			user_id: userId,
			files: payload.files,
		});
		yield put(verificationFilesSuccess(response));
		notificationContainer('Your ID has been uploaded', 'success');
		payload.success(true);

		// payload.history.push('/');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			// if (error?.response?.data.errors[0] === 'user_already_approved') {
			// 	notificationContainer('Your account was successfully verified.', 'info');
			// } else {
			// 	yield put(popUpOpen('verificationError'));
			// }
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSurveyWorker() {
	try {
		const response: ISurvObj[] = yield call(api.auth.getSurveys);
		yield put(getSurveyListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* sendSurveyAnswerWorker(action: PayloadAction<{ survey_id: number; history: History }>) {
	const { payload } = action;
	const userId: number = yield select(userToVerify);
	try {
		yield call(api.auth.sendSurveyAnswer, { survey_id: payload.survey_id, user_id: userId });
		yield payload.history.push(ELinksName.KYC);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* sendUserAgreementWorker(action: PayloadAction<{ user_id: number; success: () => void }>) {
	const { payload } = action;
	try {
		yield call(api.auth.sendAgreement, { user_id: payload.user_id });
		yield payload.success?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(registrationRequest.type, registrationRequestWorker);
	yield takeEvery(emailConfirmRequest.type, emailConfirmRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(createNewPasswordRequest.type, createNewPasswordWorker);
	yield takeEvery(getCountriesRequest.type, getCountriesWorker);
	yield takeEvery(checkEmailRequest.type, checkEmailWorker);
	yield takeLatest(verificationFilesRequest.type, verificationFilesWorker);
	yield takeLeading(getSurveyListRequest.type, getSurveyWorker);
	yield takeLeading(sendSurveyAnswerRequest.type, sendSurveyAnswerWorker);
	yield takeLeading(sendUserAgreement.type, sendUserAgreementWorker);
}
