import { IApi } from './types';
import { auth } from './auth';
import { settings } from './settings';
import { market } from './market';
import { wallets } from './wallet';
import { portfolio } from './portfolio';
import { notifications } from './notifications';

const api: IApi = {
	auth,
	settings,
	market,
	wallets,
	portfolio,
	notifications,
};

export { api };
