import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IOrderModifyRequest, IWishlistRequest } from 'redux/reducers/portfolio/types';
import { IPortfolioApi } from './types';

export const portfolio: IPortfolioApi = {
	getWishlist: (params: IWishlistRequest) => http.get(endpoint.portfolio.WISHLIST, { params }),
	deleteWishlist: (params: number) => http.delete(endpoint.portfolio.WISHLIST_DELETE(params)),
	getInvestments: (params: IWishlistRequest) =>
		http.get(endpoint.portfolio.INVESTMENTS_LIST, { params }),
	getActiveOrders: (params: IWishlistRequest) => http.get(endpoint.orders.ORDERS_LIST, { params }),
	orderModify: (payload: IOrderModifyRequest) =>
		http.post(endpoint.orders.ORDER_MODIFY, payload.data),
	cancelActiveOrder: (params: number) => http.post(endpoint.orders.CANCEL_ORDER(String(params))),
};
