import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { listSubLinks } from 'services/constants/listLinks';
import { IPropsSubDropDown } from './types';

export const SubDropDown: FC<IPropsSubDropDown> = ({ handlePopUp, subBtn, setSubBtn }) => {
	const closeSubDropDown = () => {
		setSubBtn('');
	};
	return (
		<ul
			className={classNames('sub-drop', {
				active: subBtn === 'wallet',
			})}
		>
			{listSubLinks.map((link) => (
				<li key={link.nameLink}>
					<Link
						onClick={() => {
							handlePopUp();
							closeSubDropDown();
						}}
						to={link.link}
					>
						{link.nameLink}
					</Link>
				</li>
			))}
		</ul>
	);
};
