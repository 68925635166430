import { spawn } from 'redux-saga/effects';
import { authSaga } from './reducers/auth/saga';
import { settingsSaga } from './reducers/settings/saga';
import { marketSaga } from './reducers/market/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { ordersSaga } from './reducers/orders/saga';
import { portfolioSaga } from './reducers/portfolio/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { notificationsSaga } from './reducers/notifications/saga';

// ==========================================:
export default function* rootSagas() {
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(marketSaga);
	yield spawn(walletsSaga);
	yield spawn(ordersSaga);
	yield spawn(portfolioSaga);
	yield spawn(socketsSaga);
	yield spawn(notificationsSaga);
}
