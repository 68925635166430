import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	I2faSmsRequest,
	ICheckPassRequest,
	ICreatePasswordFormData,
	IDisable2faPayload,
	IEditUserRequest,
	IEnable2faPayload,
	IGetSmsRequest,
	ISmsCodeResponse,
} from 'redux/reducers/settings/types';
import { ILoginResponseUser } from 'redux/reducers/auth/types';
import { IGenerate2faKeyResponse, ISettingsApi } from './types';

export const settings: ISettingsApi = {
	editUser: (payload: IEditUserRequest) =>
		http.post<ILoginResponseUser>(endpoint.settings.EDIT_USER, payload),

	generate2faKey: () =>
		http
			.post<IGenerate2faKeyResponse>(endpoint.settings.GENERATE_2FA_KEY)
			.then((response) => response.data),

	changePassword: (payload) =>
		http.post<ICreatePasswordFormData>(endpoint.settings.CHANGE_PASSWORD, payload),

	enable2fa: (payload) => http.post<IEnable2faPayload>(endpoint.settings.ENABLE_2FA, payload),

	disable2fa: (payload) => http.post<IDisable2faPayload>(endpoint.settings.DISABLE_2FA, payload),

	enable2faSms: (payload: I2faSmsRequest) =>
		http.post<IEnable2faPayload>(endpoint.settings.ENABLE_SMS_2FA, payload),

	disable2faSms: (payload: I2faSmsRequest) =>
		http.post<IDisable2faPayload>(endpoint.settings.DISABLE_SMS_2FA, payload),

	getSmsCode: (payload: IGetSmsRequest) =>
		http.post<ISmsCodeResponse>(endpoint.settings.GET_SMS_CODE, payload),

	getSmsCode2faLogin: (payload: IGetSmsRequest) =>
		http.post<ISmsCodeResponse>(endpoint.settings.GET_SMS_CODE_LOGIN, payload),

	checkPassword: (payload: ICheckPassRequest) =>
		http.post(endpoint.settings.CHECK_PASSWORD, payload),

	postPdf: () => http.post(endpoint.settings.POST_PDF),

	getPdf: (payload: string) => http.get(endpoint.settings.GET_PDF(payload)),
};
