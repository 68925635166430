import { lazy } from 'react';
import { isAll, isAuth, isAuthToSignup, isGuest } from 'routes/authMiddlewares';
import { ELinksName, EParams } from 'services/constants/listLinks';
import { IRouteItem } from './types';

const Login = lazy(() => import('../pages/Auth/Login'));
const SignUp = lazy(() => import('../pages/Auth/SignUp'));
const ConfirmEmail = lazy(() => import('../pages/Auth/ConfirmEmail'));
const MarketPlace = lazy(() => import('../pages/MarketPlace'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));
const CreateNewPassword = lazy(() => import('../pages/Auth/CreateNewPassword'));
const Portfolio = lazy(() => import('../pages/Portfolio'));
const CurrentBalance = lazy(() => import('../pages/CurrentBalance'));
const Settings = lazy(() => import('../pages/Settings'));
const Help = lazy(() => import('../pages/Help'));
const SellYourProperty = lazy(() => import('../pages/SellYourProperty'));
const TransactionHistory = lazy(() => import('../pages/TransactionHistory'));
const Convert = lazy(() => import('../pages/Convert'));
const MyDividends = lazy(() => import('../pages/MyDividends'));
const Tokens = lazy(() => import('../pages/Tokens'));
const TermsOfService = lazy(() => import('../pages/TermsOfService'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const Faq = lazy(() => import('../pages/Faq'));
const PropertyDetails = lazy(() => import('../pages/PropertyDetails'));
const InvestNow = lazy(() => import('../pages/InvestNow/InvestNow'));
const InvestCompleted = lazy(() => import('../pages/InvestCompleted'));
const AmlPolicy = lazy(() => import('../pages/AmlPolicy/AmlPolicy'));
const SignUpOrResetPasswordSuccess = lazy(() => import('../pages/SignUpOrResetPasswordSuccess'));
const KYC = lazy(() => import('../pages/Auth/Kyc'));
const Survey = lazy(() => import('../pages/Auth/Survey'));
const Agreement = lazy(() => import('pages/Auth/Agreement'));

const navList = {
	login: {
		path: ELinksName.LOGIN,
		component: isGuest(Login),
	},

	registr: {
		path: ELinksName.REGISTRATION,
		component: isGuest(SignUp),
	},

	confirmEmail: {
		path: ELinksName.CONFIRM_EMAIL,
		component: isAll(ConfirmEmail),
	},

	resetPassword: {
		path: ELinksName.RESET_PASSWORD,
		component: isGuest(ResetPassword),
	},

	createNewPassword: {
		path: ELinksName.CREATE_NEW_PASSWORD,
		component: isGuest(CreateNewPassword),
	},

	marketplace: {
		path: ELinksName.MARKETPLACE,
		component: isAll(MarketPlace),
	},

	portfolio: {
		path: ELinksName.PORTFOLIO + EParams.TAB,
		component: isAuth(Portfolio),
	},

	currentBalance: {
		path: ELinksName.CURRENT_BALANCE + EParams.TAB + EParams.ID,
		component: isAuth(CurrentBalance),
	},

	settings: {
		path: ELinksName.SETTINGS + EParams.TAB + EParams.SUBTAB,
		component: isAuth(Settings),
	},

	help: {
		path: ELinksName.HELP,
		component: isAuth(Help),
	},

	sellYourProperty: {
		path: ELinksName.SELL_YOUR_PROPERTY,
		component: isAll(SellYourProperty),
	},

	transactionHistory: {
		path: ELinksName.TRANSACTION_HISTORY + EParams.TAB + EParams.SUBTAB,
		component: isAuth(TransactionHistory),
	},

	convert: {
		path: ELinksName.CONVERT,
		component: isAuth(Convert),
	},

	myDividends: {
		path: ELinksName.MY_DIVIDENDS,
		component: isAuth(MyDividends),
	},

	tokens: {
		path: ELinksName.TOKENS + EParams.TAB,
		component: isAuth(Tokens),
	},

	termsOfService: {
		path: ELinksName.TERMS_OF_SERVICE,
		component: isAll(TermsOfService),
	},

	privacyPolicy: {
		path: ELinksName.PRIVACY_POLICY,
		component: isAll(PrivacyPolicy),
	},

	amlPolicy: {
		path: ELinksName.AML_POLICY,
		component: isAll(AmlPolicy),
	},

	faq: {
		path: ELinksName.FAQ,
		component: isAll(Faq),
	},

	propertyDetails: {
		path: ELinksName.PROPERTY_DETAILS + EParams.ID,
		component: isAll(PropertyDetails),
	},

	investNow: {
		path: ELinksName.INVEST_NOW + EParams.ID,
		component: isAuthToSignup(InvestNow),
	},

	investCompleted: {
		path: ELinksName.INVEST_COMPLETED,
		component: isAuth(InvestCompleted),
	},

	signUpOrResetPasswordSuccess: {
		path: ELinksName.SUCCESS + EParams.PAGE,
		component: isAll(SignUpOrResetPasswordSuccess),
	},
	surveyPage: {
		path: ELinksName.SURVEY,
		component: isGuest(Survey),
	},
	kycPage: {
		path: ELinksName.KYC,
		component: isGuest(KYC),
	},
	agreementPage: {
		path: ELinksName.AGREEMENT,
		component: isGuest(Agreement),
	},
	myDocumentsPage: {
		path: ELinksName.AGREEMENT,
		component: isGuest(Agreement),
	},
};

// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
