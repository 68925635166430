import { ISingDocumentsParams } from 'redux/reducers/market/types';
import { ISubscribeRequest } from 'redux/reducers/notifications/types';

export const endpoint = {
	auth: {
		LOGIN: '/auth/login',
		EMAIL_CONFIRM: '/confirmation/email',
		REGISTRATION: '/auth/register',
		LOGOUT: '/auth/logout',
		RESET_PASSWORD: '/password/email/send_reset_link',
		NEW_PASSWORD: '/password/email/change',
		COUNTRIES_LIST: '/auth/countries_list',
		CHECK_EMAIL: 'auth/check_email',
		CONFIRMATION_FILES_UPLOAD: '/confirmation/kyc/upload',
		GET_SURVEYS: '/confirmation/surveys',
		SEND_SURVEY: '/confirmation/survey',
		SEND_AGRREMENT: '/confirmation/agreement',
	},

	settings: {
		EDIT_USER: '/settings/profile/edit',
		CHECK_PASSWORD: '/settings/check/password',
		CHANGE_PASSWORD: '/settings/change/password',
		GENERATE_2FA_KEY: '/settings/2fa/google/generate_secret',
		ENABLE_2FA: '/settings/2fa/google/enable',
		DISABLE_2FA: '/settings/2fa/google/disable',
		ENABLE_SMS_2FA: '/settings/2fa/sms/enable',
		DISABLE_SMS_2FA: '/settings/2fa/sms/disable',
		RESEND: '/2fa/sms',
		GET_SMS_CODE: '/2fa/user/send_sms',
		GET_SMS_CODE_LOGIN: '/2fa/send_sms/email',
		POST_PDF: '/agreement/pdf/create',
		GET_PDF: (params: string) => `/agreement/pdf/download/${params}`,
	},

	market: {
		SELL_YUOR_PROPERTY: '/property/add',
		ADD_PICTURES: '/property/add_pictures',
		GET_ALL_PROPERTY: '/estate/marketplace',
		WAITLIST_ADD: '/estate/waitlist/add',
		WISHLIST_ADD: '/wishlist/add',
		GET_TOKEN_PRICE: '/investments/token_price',
		GET_WISHLIST_ID: '/wishlist/user_ids',
		GET_SING_DOCUMENTS: (params: ISingDocumentsParams) =>
			`/investments/add?estate_id=${String(params.estate_id)}&amount_tokens=${String(
				params.amount_tokens,
			)}&date_invesment=${String(params.date_invesment)}`,
		CREATE_INVEST: '/investments/docusign/complete_signature',
		ADD_COMPLETED: '/investments/add_confirmed_document',
		GET_CALCULATE_AMOUNT: (params: number) => `/investments/calculate_amount/${params}`,
		PROPERTY_DETAILS: (id: string) => `/estate/show/${id}`,
		STATES_LIST: `/auth/states_list`,
	},

	orders: {
		ORDERS_BUY: (payload: number) => `/orders/buy/${payload}`,
		ORDERS_DELETE_FROM_WISHLIST: (payload: number) => `/wishlist/delete_estate/${payload}`,
		ORDERS_SELL: (payload: number) => `/orders/sell/${payload}`,
		ORDERS_TRADE: `/orders/trade`,
		ORDERS_CREATE: `/orders/create`,
		ORDERS_LIST: `/orders/list`,
		ORDER_MODIFY: `/orders/modify`,
		DELETE_ORDER: (param: string) => `/orders/delete/${param}`,
		CANCEL_ORDER: (param: string) => `/orders/cancel/${param}`,
	},

	portfolio: {
		WISHLIST: '/wishlist',
		WISHLIST_DELETE: (id: number) => `/wishlist/delete/${id}`,
		INVESTMENTS_LIST: '/investments/list',
	},

	wallet: {
		WALLET_BALANCE: '/wallet/balance',
		WALLET_HISTORY: '/wallet/history',
		CREATE_WALLET: '/settings/pay',
		CONVERT_WALLET: '/settings/convert',
		CALCULATE: '/calculate/convert',
		COMMISION: '/assets',
		COMMISION_CALCULATE: '/calculate/commission',
		LIMITS: '/limits',
		DIVIDENDS_ALL: '/wallet/dividends/sum',
		DIVIDENDS_HISTORY: '/wallet/dividends/history',
		CURRENT_BALANCE: '/wallet/tokens/show',
		GET_APARTMENT_INVEST: '/investments/list',
		GET_HISTORY_CONVERTS: '/wallet/converts',
	},

	notifications: {
		NOTIFICATION: '/settings/notifications',
		NOTIFICATION_BELL: '/settings/notifications/bell',
		SUBSCRIBE: (params: ISubscribeRequest) => `/subscribe?email=${String(params.email)}`,
		UNSUBSCRIBE: '/subscribe/delete',
	},
};
