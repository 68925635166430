/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotifications, ISocketsStore } from './types';

export const initialState: ISocketsStore = {
	isSocketConnection: false,
	socketToken: null,
};

const sockets = createSlice({
	name: '@@sockets',
	initialState,
	reducers: {
		setSocketsConnect: (state) => {
			state.isSocketConnection = true;
		},

		setSocketsDisconnect: (state) => {
			state.isSocketConnection = false;
		},

		socketOpenConnection: (state, { payload }: PayloadAction<string | null>) => {
			state.socketToken = payload;
		},

		socketClosedConnection: () => {},

		socketSendMessage: (state, action: PayloadAction<[number, string]>) => {},

		socketsInitState: () => initialState,
	},
});

export default sockets.reducer;
export const {
	setSocketsConnect,
	setSocketsDisconnect,
	socketOpenConnection,
	socketsInitState,
	socketClosedConnection,
	socketSendMessage,
} = sockets.actions;
