import {
	ICreateNewPasswordFormData,
	IEmailConfirmPayload,
	IForgotPasswordPayload,
	IForgotPasswordResponse,
	ILoginPayload,
	ILoginResponse,
	IRegistrResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http, httpUpload } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginPayload, { data: ILoginResponse }>(endpoint.auth.LOGIN, payload)
			.then((response) => {
				return response.data;
			}),

	registration: (payload) =>
		http
			.post<IRegistrResponse>(endpoint.auth.REGISTRATION, payload)
			.then((response) => response.data),

	forgotPassword: (payload) =>
		http
			.post<IForgotPasswordPayload, { data: IForgotPasswordResponse }>(
				endpoint.auth.RESET_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	newPassword: (payload) =>
		http
			.put<ICreateNewPasswordFormData, { data: IRegistrResponse }>(
				endpoint.auth.NEW_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	emailConfirm: (payload) =>
		http.post<IEmailConfirmPayload>(endpoint.auth.EMAIL_CONFIRM, { token: payload.token }),
	getCountries: () => http.get(endpoint.auth.COUNTRIES_LIST),

	logout: () => http.post(endpoint.auth.LOGOUT),
	checkEmail: (payload: { email: string }) => http.post(endpoint.auth.CHECK_EMAIL, payload),
	sendVerificationFiles: (payload) =>
		httpUpload
			.post(endpoint.auth.CONFIRMATION_FILES_UPLOAD, payload.files, {
				headers: { 'X-User-Id': String(payload.user_id) },
			})
			.then((response) => response.data),
	getSurveys: () => http.get(endpoint.auth.GET_SURVEYS).then((response) => response.data),
	sendSurveyAnswer: (payload) =>
		http.post(endpoint.auth.SEND_SURVEY, payload).then((response) => response.data),
	sendAgreement: (payload) =>
		http.post(endpoint.auth.SEND_AGRREMENT, payload).then((response) => response.data),
};
