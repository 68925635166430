/* eslint-disable import/no-named-as-default */
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import errors from 'redux/reducers/errors/reducer';
import settings from 'redux/reducers/settings/reducer';
import market from 'redux/reducers/market/reducer';
import walletSlice from 'redux/reducers/wallets/reducer';
import orders from 'redux/reducers/orders/reducer';
import portfolio from 'redux/reducers/portfolio/reducer';
import sockets from 'redux/reducers/sockets/reducer';
import notifications from 'redux/reducers/notifications/reducer';

// ==========================================:
const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		app,
		auth,
		settings,
		errors,
		market,
		walletSlice,
		orders,
		portfolio,
		sockets,
		notifications,
	});

export default createRootReducer;
