import classNames from 'classnames';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getUser } from 'redux/reducers/auth/selectors';
import { putStateUsa, putStatus, putTypeNumber } from 'redux/reducers/market/reducer';
import { ELinksName, listSubLinks } from 'services/constants/listLinks';
import { SvgHelp, SvgLogout, SvgSelectArrow, SvgSettings, SvgWallet } from 'ui/svg-img/SvgHeader';
import { IPropsPopUp } from './type';

export const PopUpActivMenu: FC<IPropsPopUp> = ({ activBurger }) => {
	const [openPopUp, setOpenPopUp] = useState(false);
	const [openSubPopUp, setOpenSubPopUp] = useState(false);
	const userData = useSelector(getUser);
	const dispatch = useDispatch();
	const history = useHistory();
	const { innerBorderRef } = useOnOutsideClick(() => setOpenPopUp(false));

	const handlePopUp = () => {
		setOpenPopUp(!openPopUp);
	};

	const handleSubPopUp = () => {
		setOpenSubPopUp(!openSubPopUp);
	};

	const handelLogout = () => {
		dispatch(putTypeNumber(0));
		dispatch(putStatus(0));
		dispatch(putStateUsa(''));
		dispatch(logoutRequest({ history }));
	};

	return (
		<div className="authorization-btns">
			<div
				ref={innerBorderRef}
				className={classNames('select select--user select--width', {
					active: openPopUp,
				})}
			>
				<button onClick={handlePopUp} type="button" className="select__current">
					<span className="select-icon select-icon--color">
						<SvgSelectArrow />
					</span>
					{userData?.name}
					<span className="select__current-arrow">
						<svg
							width="14"
							height="8"
							viewBox="0 0 14 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
								fill="#25252E"
							/>
						</svg>
					</span>
				</button>
				<div className="select__drop">
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li className="active">
									<button onClick={handleSubPopUp} type="button">
										<span className="select-icon select-icon--dark">
											<SvgWallet />
										</span>
										Wallet
										<span className="select-icon select-icon--arrow">
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
													fill="#25252E"
												/>
											</svg>
										</span>
									</button>
									<ul
										className={classNames('sub-drop', {
											active: openSubPopUp,
										})}
									>
										{listSubLinks.map((link) => (
											<li key={link.nameLink}>
												<Link
													onClick={() => {
														handlePopUp();
														handleSubPopUp();
														activBurger();
													}}
													to={link.link}
												>
													{link.nameLink}
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li>
									<Link
										onClick={() => {
											handlePopUp();
											handleSubPopUp();
											activBurger();
										}}
										to={ELinksName.SETTINGS}
									>
										<span className="select-icon select-icon--dark ">
											<SvgSettings />
										</span>
										Settings
									</Link>
								</li>
								<li>
									<Link
										onClick={() => {
											handlePopUp();
											handleSubPopUp();
											activBurger();
										}}
										to={ELinksName.HELP}
									>
										<span className="select-icon select-icon--dark">
											<SvgHelp />
										</span>
										Help
									</Link>
								</li>
								<li>
									<button onClick={handelLogout} type="button">
										<span className="select-icon select-icon--dark">
											<SvgLogout />
										</span>
										Logout
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
