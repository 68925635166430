import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getNotificationsRequest,
	getNotificationsSuccess,
	notificationsInitState,
	subscribeToNewsletterRequest,
	unsubscribeToNewsletterRequest,
} from './reducer';
import { INotificationsRequest, INotificationsResponse, ISubscribeRequest } from './types';

function* getNotificationsWorker(action: PayloadAction<INotificationsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: INotificationsResponse } = yield call(
			api.notifications.getNotifications,
			payload,
		);
		yield put(getNotificationsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(notificationsInitState());
	}
}

function* subscribeToNewsletterWorker(action: PayloadAction<ISubscribeRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.notifications.subscribe, payload);
		yield notificationContainer('You have successfully subscribed to the newsletter', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(notificationsInitState());
	}
}

function* unsubscribeToNewsletterWorker() {
	try {
		yield put(showLoading());
		yield call(api.notifications.unsubscribe);
		yield notificationContainer('You have unsubscribed from the newsletter', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(notificationsInitState());
	}
}

export function* notificationsSaga() {
	yield takeEvery(getNotificationsRequest.type, getNotificationsWorker);
	yield takeEvery(subscribeToNewsletterRequest.type, subscribeToNewsletterWorker);
	yield takeEvery(unsubscribeToNewsletterRequest.type, unsubscribeToNewsletterWorker);
}
