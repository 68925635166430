import { FC, MouseEvent } from 'react';
import { IHeaderProps } from 'components/Header/types';
import { Link, useHistory } from 'react-router-dom';
import { ELinksName } from 'services/constants/listLinks';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { PopUpMenu } from 'components/PopUpMenu';
import classNames from 'classnames';
import { SvgBlackNavBtn } from 'ui/svg-img/SvgHeader';
import logo from '../../assets/images/Logo.svg';

export const HeaderNew: FC<IHeaderProps> = ({ activBurger, burger }) => {
	const auth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();

	const heandelLink = (event: MouseEvent) => {
		const target = event.target as HTMLLinkElement;
		history.push(target.href);
	};

	return (
		<header className="header header--home header--custom">
			<div className="header__container  ">
				<div className="header-left">
					<Link to="/" className="logo">
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<div className="nav-block">
					<div className="nav-block__inner">
						<nav className="main-nav">
							<div className="nav-item">
								<Link to={ELinksName.MARKETPLACE} className="nav-item__link">
									Marketplace
								</Link>
							</div>
							{auth && (
								<div className="nav-item">
									<Link to={ELinksName.PORTFOLIO} className="nav-item__link">
										Portfolio
									</Link>
								</div>
							)}
							<div className="nav-item">
								<a onClick={heandelLink} href="#hove-it-work" className="nav-item__link">
									How it works
								</a>
							</div>
							<div className="nav-item">
								<a onClick={heandelLink} href="#who-we-are" className="nav-item__link">
									Who we are
								</a>
							</div>
							{/* <div className="nav-item">
								<a onClick={heandelLink} href="#legal-matters" className="nav-item__link">
									Legal metters
								</a>
							</div>
							<div className="nav-item">
								<Link className="nav-item__link" to={ELinksName.SELL_YOUR_PROPERTY}>
									Sell your property
								</Link>
							</div> */}
							<div className="nav-item">
								<Link className="nav-item__link" to={ELinksName.FAQ}>
									FAQ
								</Link>
							</div>
						</nav>
						{!auth ? (
							<div className="authorization-btns">
								<Link to={ELinksName.LOGIN} className="button button--type2 button--authorization">
									Log In
								</Link>
								<Link to={ELinksName.REGISTRATION} className="button button--authorization">
									Sign Up
								</Link>
							</div>
						) : (
							<PopUpMenu />
						)}
					</div>
				</div>

				<button
					onClick={activBurger}
					type="button"
					className={classNames('nav-btn', {
						active: burger,
					})}
				>
					<SvgBlackNavBtn />
				</button>
			</div>
		</header>
	);
};
